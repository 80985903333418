const initState = {
  uploadProgress: { percent: 0, total: 0, uploaded: 0 },
};

const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPLOAD_PROGRESS_EVENT":
      return {
        ...state,
        uploadProgress: { ...action.data },
      };
    default:
      break;
  }
  return state;
};

export default notificationReducer;
