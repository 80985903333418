import React, { Component } from "react";
import QFloMapMarker from "./QFloMapMarker";
import GoogleMap from "google-maps-react-markers";
import { regOrgBranches } from "../api/QfloAPI/branchAPIService";
import { connect } from "react-redux";
import { toast } from "react-toastify";
class QfloBranchInfoOnboardingCard extends Component {
  state = {
    isNew: true,
    revealForm: false,
    facilities: [],
    lng: 0,
    lat: 0,
    branchName: null,
    containerStyle: {
      width: "400px",
      height: "400px",
    },
    center: { lat: 0, lng: 0 },
    zoom: 10,
    gettingLocation: false,
    markers: [
      {
        position: { lat: 0, lng: 0 },
      },
    ],
    targetFacility: null,
  };

  componentDidMount() {
    this.getLocation();
  }
  updateInfo = (e) => {
    e.preventDefault();
    let body = {
      organisationId: this.props.client._id,
      long: this.state.center.lng,
      lat: this.state.center.lat,
      branchName: this.state.branchName,
    };
  };

  saveInfo = (e) => {
    e.preventDefault();
    let body = {
      organisationId: this.props.client._id,
      long: this.state.center.lng,
      lat: this.state.center.lat,
      branchName: this.state.branchName,
    };

    const exttoast = toast.loading("Registering Branch !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });

    regOrgBranches(body)
      .then((res) => {
        this.setState({
          facilities: [...this.state.facilities, res.data],
          revealForm: false,
          branchName: null,
        });
        toast.update(exttoast, {
          render: "Complete",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.update(exttoast, {
          render: "Failed to register branch!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
  };

  updateFacility = (facility) => {
    this.setState({
      revealForm: true,
      isNew: false,
      targetFacility: facility._id,
      branchName: facility.branchName,
      center: { ...facility.location, lng: facility.location.long },
    });
  };

  showForm = () => {
    // this.getLocation();
    this.setState({ revealForm: true, isNew: true, branchName: null });
  };

  getLocation() {
    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorString = "Geolocation is not available.";
      return;
    }

    this.setState({ gettingLocation: true });

    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const { latitude, longitude } = pos.coords;

        console.log({
          lat: latitude,
          lng: longitude,
          center: { lat: latitude, lng: longitude },
          markers: [{ position: { lat: latitude, lng: longitude } }],
          gettingLocation: false,
        });
        this.setState({
          lat: latitude,
          lng: longitude,
          center: { lat: latitude, lng: longitude },
          markers: [{ position: { lat: latitude, lng: longitude } }],
          gettingLocation: false,
        });
      },
      (err) => {
        console.log(err);
        this.setState({ gettingLocation: false });
        // this.gettingLocation = false;
        // this.errorString = err.message;
      }
    );
  }

  markerMoved = (newCord) => {
    console.log(newCord);
    this.setState({ center: newCord });
  };

  render() {
    return (
      <>
        <div className="page-separator">
          <div className="page-separator__text">Client Branches</div>
        </div>
        <div className="row align-items-center mb-24pt mb-lg-32pt">
          {this.state.facilities.map((facility, idx) => (
            <div
              key={idx}
              onClick={() => this.updateFacility(facility)}
              className="col-3 mb-3 position-relative"
              style={{ cursor: "pointer" }}
            >
              <span
                className="d-flex flex-column border-1 rounded bg-alt px-16pt py-8pt lh-1"
                href=""
              >
                <small>
                  <strong>{facility.branchName}</strong>
                </small>
                {/* <small className="text-50">Location</small> */}
              </span>
              <span className="position-close-18pt">
                <i className="material-icons text-50 icon-16pt">close</i>
              </span>
            </div>
          ))}
          <span
            onClick={() => this.showForm()}
            className="col-3 mb-3 d-flex flex-column border-1 rounded bg-alt px-16pt py-8pt lh-1 text-center"
            style={{ cursor: "pointer" }}
          >
            <i className="material-icons text-50 icon-16pt">add</i>
            <small>
              <strong>New Branch</strong>
            </small>
          </span>
        </div>

        {this.state.revealForm && this.state.isNew ? (
          <form
            className="card--connect pb-32pt pb-lg-64pt"
            onSubmit={(e) => this.saveInfo(e)}
          >
            <div className="card o-hidden mb-0">
              <div className="card-header bg-transparent">
                <h5 className="text-uppercase mb-0">Branch information</h5>
              </div>
              <div className="card-body">
                <div
                  className="form-group m-0"
                  role="group"
                  aria-labelledby="label-title"
                >
                  <div className="form-row align-items-center">
                    <label
                      id="label-title"
                      for="title"
                      className="col-md-3 col-form-label form-label"
                    >
                      Name
                    </label>
                    <div className="col-md-9">
                      <input
                        id="branch_name"
                        type="text"
                        placeholder="Branch name"
                        value={this.state.branchName}
                        onChange={(e) =>
                          this.setState({ branchName: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div
                  className="form-group m-0"
                  role="group"
                  aria-labelledby="label-title"
                >
                  <div className="form-row align-items-center">
                    <div style={{ height: "40vh", width: "100%" }}>
                      <p>
                        Lat: {this.state.center.lat}, Long:{" "}
                        {this.state.center.lng}
                      </p>
                      <GoogleMap
                        apiKey="AIzaSyAjwovKZ11EaLszLXhYUTHDC89Rs2QEfC4"
                        defaultCenter={this.state.center}
                        defaultZoom={10}
                        // options={mapOptions}
                        mapMinHeight="100%"
                        // onGoogleApiLoaded={onGoogleApiLoaded}
                        onChange={(map) => console.log("Map moved", map)}
                      >
                        {this.state.markers.map((elem, index) => (
                          <QFloMapMarker
                            key={index}
                            lat={elem.position.lat}
                            lng={elem.position.lng}
                            markerId={index}
                            // onClick={onMarkerClick} // you need to manage this prop on your Marker component!
                            draggable={true}
                            // onDragStart={(e, { latLng }) => {}}
                            // onDrag={(e, { latLng }) => {}}
                            onDragEnd={(e, { latLng }) => {
                              this.markerMoved(latLng);
                            }}
                          />
                        ))}
                      </GoogleMap>
                    </div>
                  </div>
                </div>
              </div>

              <div className="list-group-item col-12">
                <button
                  type="submit"
                  className="btn btn-accent"
                  style={{ float: "right" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        ) : this.state.revealForm && this.state.isNew === false ? (
          <form
            className="card--connect pb-32pt pb-lg-64pt"
            onSubmit={(e) => this.updateInfo(e)}
          >
            <div className="card o-hidden mb-0">
              <div className="card-header bg-transparent">
                <h5 className="text-uppercase mb-0">Branch information</h5>
              </div>
              <div className="card-body">
                <div
                  className="form-group m-0"
                  role="group"
                  aria-labelledby="label-title"
                >
                  <div className="form-row align-items-center">
                    <label
                      id="label-title"
                      for="title"
                      className="col-md-3 col-form-label form-label"
                    >
                      Name
                    </label>
                    <div className="col-md-9">
                      <input
                        id="branch_name"
                        type="text"
                        placeholder="Branch name"
                        value={this.state.branchName}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div
                  className="form-group m-0"
                  role="group"
                  aria-labelledby="label-title"
                >
                  <div className="form-row align-items-center">
                    <div style={{ height: "40vh", width: "100%" }}>
                      <p>
                        Lat: {this.state.center.lat}, Long:{" "}
                        {this.state.center.lng}
                      </p>
                      <GoogleMap
                        apiKey="AIzaSyAjwovKZ11EaLszLXhYUTHDC89Rs2QEfC4"
                        defaultCenter={this.state.center}
                        defaultZoom={10}
                        // options={mapOptions}
                        mapMinHeight="100%"
                        // onGoogleApiLoaded={onGoogleApiLoaded}
                        onChange={(map) => console.log("Map moved", map)}
                      >
                        {this.state.markers.map((elem, index) => (
                          <QFloMapMarker
                            key={index}
                            lat={elem.position.lat}
                            lng={elem.position.lng}
                            markerId={index}
                            // onClick={onMarkerClick} // you need to manage this prop on your Marker component!
                            draggable={true}
                            // onDragStart={(e, { latLng }) => {}}
                            // onDrag={(e, { latLng }) => {}}
                            onDragEnd={(e, { latLng }) => {
                              this.markerMoved(latLng);
                            }}
                          />
                        ))}
                      </GoogleMap>
                    </div>
                  </div>
                </div>
              </div>

              <div className="list-group-item col-12">
                <button
                  type="submit"
                  className="btn btn-accent"
                  style={{ float: "right" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.clientData.client,
    contact: state.clientData.contact,
    user: state.userData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QfloBranchInfoOnboardingCard);
