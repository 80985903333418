import React, { Component } from "react";

class StaffPasswordResetCard extends Component {

  state = {
    updating:false
  }


  updateInfo = () => {

  }

  saveInfo = () =>{

  }
  
    render() {
  
      return (
        <>
            <div className="page-section">
                <h4>Change Password</h4>

                <div className="alert alert-soft-warning">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-8pt">
                            <i className="material-icons">check_circle</i>
                        </div>
                        <div className="flex"
                            style={{minWidth: "180px"}}>
                            <small className="text-100">
                                An email with password reset instructions has been sent to your email address, if it exists on our system.
                            </small>
                        </div>
                    </div>
                </div>

                <div className="list-group list-group-form">
                    <div className="list-group-item">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-3">New password</label>
                            <div className="col-sm-9">
                                <input type="password"
                                        className="form-control"
                                        placeholder="Password ..."/>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-3">Confirm password</label>
                            <div className="col-sm-9">
                                <input type="password"
                                        className="form-control"
                                        placeholder="Confirm password ..."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
      );
  
    }
  
}

export default StaffPasswordResetCard