import React, { Component } from "react";
import { getOrgBranches } from "../api/QfloAPI/branchAPIService";

class QfloEmployeeInfoCard extends Component {
  state = {
    isUpdating: false,
    employee: {
      profile: { name: {} },
    },
    branches: [],
  };

  updateInfo = () => {};

  saveInfo = () => {};

  componentDidMount() {
    this.setState({ employee: this.props.employee });
    getOrgBranches(this.props.employee.organisation).then((res) => {
      this.setState({ branches: res.data });
    });
  }

  render() {
    return (
      <>
        <div className="page-section">
          <div className="page-separator">
            <div className="page-separator__text" style={{ cursor: "pointer" }}>
              Back
            </div>
          </div>
          <div className="list-group list-group-form">
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  First Name
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.employee?.profile?.name?.firstname}
                    placeholder="First name"
                  />
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Last Name
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.employee?.profile?.name?.lastname}
                    placeholder="Last name"
                  />
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Work Email
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.employee?.email}
                    placeholder="email"
                  />
                </div>
              </div>
            </div>

            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Gender
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.employee.gender}
                    placeholder="Gender"
                  />
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Branch
                </label>
                <div className="col-sm-9">
                  <select
                    id="branch"
                    data-toggle="select"
                    className="form-control"
                  >
                    {this.state.branches.map((record) =>
                      record._id == this.state.employee?.branch?._id ? (
                        <option selected>{record.branchName}</option>
                      ) : (
                        <option>{record.branchName}</option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QfloEmployeeInfoCard;
