import React, { Component } from "react";

class StaffInfoCard extends Component {

  state = {
    updating:false
  }


  updateInfo = () => {

  }

  saveInfo = () =>{

  }
  
    render() {
  
      return (
        <>
          <div className="page-section">
            <h4>Account</h4>
            <div className="list-group list-group-form">
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">Your photo</label>
                        <div className="col-sm-9 media align-items-center">
                            <a href=""
                                className="media-left mr-16pt">
                                <img src="/assets/images/people/110/guy-3.jpg"
                                      alt="people"
                                      width="56"
                                      className="rounded-circle" />
                            </a>
                            
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">Huma profile name</label>
                        <div className="col-sm-9 media">
                            <p>User names</p>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">Email Address</label>
                        <div className="col-sm-9 media">
                            <p>Email Address</p>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">Number</label>
                        <div className="col-sm-9 media">
                            <p>77777777</p>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">About you</label>
                        <div className="col-sm-9 media">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum similique quisquam doloribus nulla illo, voluptatibus fuga totam aliquam placeat vel laboriosam expedita, quis tenetur possimus optio, laudantium amet adipisci quas!</p>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div role="group"
                        aria-labelledby="label-question"
                        className="m-0 form-group">
                        <div className="form-row">
                            <label id="label-question"
                                htmlFor="question"
                                className="col-md-3 col-form-label form-label">Roles</label>
                            <div className="col-md-9">
                                <select id="user_roles"
                                        data-toggle="select"
                                        multiple
                                        value={["Administrator"]}
                                        onChange={() => {}}
                                        className="form-control">
                                    <option>Administrator</option>
                                    <option >Account manager</option>
                                    <option>Tech Support</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- <div className="list-group-item">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                                className="custom-control-input"
                                checked
                                id="customCheck1">
                        <label className="custom-control-label"
                                htmlFor="customCheck1">Display your real name on your profile</label>
                        <small className="form-text text-muted">If unchecked, your profile name will be displayed instead of your full name.</small>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                                className="custom-control-input"
                                checked
                                id="customCheck2">
                        <label className="custom-control-label"
                                htmlFor="customCheck2">Allow everyone to see your profile</label>
                        <small className="form-text text-muted">If unchecked, your profile will be private and no one except you will be able to view it.</small>
                    </div>
                </div> --> */}
            </div>
        </div>
        </>
      );
  
    }
  
}

export default StaffInfoCard