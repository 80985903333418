import React, { Component } from "react";
import { login } from "../api/Genesis/AuthAPIService";
import { toast } from "react-toastify";
import { getCurrentUser } from "../store/actions/authActions";
import { connect } from "react-redux";

class Login extends Component {
  state = {
    email: null,
    pass: null,
  };

  signin = (e) => {
    const exttoast = toast.loading("Signing IN !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });

    e.preventDefault();
    login({ email: this.state.email, password: this.state.pass })
      .then(() => {
        this.props
          .me()
          .then(() => {
            window.location.href = "/admin";
          })
          .catch((err) => {
            console.log(err);
            toast.update(exttoast, {
              render: "User cannot be verified!",
              type: "error",
              isLoading: false,
              autoClose: 2000,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        toast.update(exttoast, {
          render: "Invalid credentials!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
    // console.log("login")
  };

  render() {
    return (
      <div className="mdk-drawer-layout__content page-content">
        <div className=" pt-64pt pt-sm-64pt pb-32pt">
          <div className="container-fluid page__container">
            <form
              onSubmit={(e) => this.signin(e)}
              className="col-md-5 p-0 mx-auto"
            >
              <div className="form-group text text-center">
                <p
                  className="text-70 brand mb-24pt"
                  style={{ display: "block" }}
                >
                  <img
                    className="brand-icon"
                    src="/assets/images/logo/black-70@2x.png"
                    width="30"
                    alt="Huma"
                  />{" "}
                  Genesis
                </p>
              </div>
              <div className="form-group">
                <label className="form-label" htmlfor="email">
                  Email:
                </label>
                <input
                  id="email"
                  required
                  type="email"
                  className="form-control"
                  placeholder="Your email address ..."
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlfor="password">
                  Password:
                </label>
                <input
                  id="password"
                  required
                  type="password"
                  className="form-control"
                  placeholder="Your first and last name ..."
                  value={this.state.pass}
                  onChange={(e) => this.setState({ pass: e.target.value })}
                />
                {/* <p className="text-right"><a href=""
                                    className="small">Forgot your password?</a></p> */}
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-accent-red">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    me: () => dispatch(getCurrentUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
