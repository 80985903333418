import React, { Component } from "react";
import QfloGroupInfoCard from "./QfloServiceGroupInfoCard";
import { getOrgServiceGroups } from "../api/QfloAPI/queueServiceGroupAPIService";
import moment from "moment";

class QfloServiceGroupListInfoCard extends Component {
  state = {
    isUpdating: false,
    selectedGroup: {},
    serviceGroups: [],
    focusArea: "groupList",
  };

  updateInfo = () => {};

  saveInfo = () => {};

  componentDidMount() {
    getOrgServiceGroups(this.props.client._id)
      .then((res) => {
        console.log(res.data);
        this.setState({ serviceGroups: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  selectGroup = (group) => {
    this.setState({ selectedGroup: group, focusArea: "group" });
  };

  render() {
    return this.state.focusArea == "groupList" ? (
      <div className="page-section">
        {/* <div className="page-separator">
                <div className="page-separator__text">groups</div>
            </div> */}

        <div className="card mb-0">
          <div
            className="table-responsive"
            data-toggle="lists"
            data-lists-sort-by="js-lists-values-employee-name"
            data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'
          >
            <div className="card-header">
              <form className="form-inline">
                <label className="mr-sm-2 form-label" for="inlineFormFilterBy">
                  Filter by:
                </label>
                <input
                  type="text"
                  className="form-control search mb-2 mr-sm-2 mb-sm-0"
                  id="inlineFormFilterBy"
                  placeholder="Search ..."
                />

                <label className="sr-only" for="inlineFormRole">
                  branch
                </label>
                <select
                  id="inlineFormRole"
                  className="custom-select mb-2 mr-sm-2 mb-sm-0"
                >
                  <option value="All Roles">Branch 1</option>
                </select>

                <div className="ml-auto mb-2 mb-sm-0 custom-control-inline mr-0">
                  <label className="form-label mb-0" for="active">
                    Active
                  </label>
                  <div className="custom-control custom-checkbox-toggle ml-8pt">
                    <input
                      checked=""
                      type="checkbox"
                      id="active"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="active">
                      Active
                    </label>
                  </div>
                </div>

                {/* <!-- <div className="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
    <input type="checkbox" className="custom-control-input" id="inlineFormPurchase">
    <label className="custom-control-label" for="inlineFormPurchase">Made a Purchase?</label>
    </div> --> */}
              </form>
            </div>

            <table className="table mb-0 thead-border-top-0 table-nowrap">
              <thead>
                <tr>
                  <th>
                    <span
                      className="sort"
                      data-sort="js-lists-values-employee-name"
                    >
                      Name
                    </span>
                  </th>

                  <th className="text-center" style={{ width: "48px" }}>
                    <span className="sort" data-sort="js-lists-values-projects">
                      Prefix
                    </span>
                  </th>

                  <th style={{ width: "120px" }}>
                    <span className="sort" data-sort="js-lists-values-activity">
                      Remote
                    </span>
                  </th>
                  <th style={{ width: "120px" }}>
                    <span className="sort" data-sort="js-lists-values-activity">
                      Available
                    </span>
                  </th>
                  <th style={{ width: "120px" }}>
                    <span className="sort" data-sort="js-lists-values-activity">
                      Created At
                    </span>
                  </th>

                  <th style={{ width: "24px" }} className="pl-0"></th>
                </tr>
              </thead>
              <tbody className="list" id="staff">
                {this.state.serviceGroups.map((record) => (
                  <tr className="selected">
                    <td onClick={() => this.selectGroup(record)}>
                      <div
                        className="media flex-nowrap align-items-center"
                        style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                      >
                        <div className="media-body">
                          <div className="d-flex flex-column">
                            <p className="mb-0">
                              <strong className="js-lists-values-employee-name">
                                {record.name}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex align-items-center">
                        <span className="text-70">
                          <span className="js-lists-values-employer-name">
                            {record.prefix}
                          </span>
                        </span>
                      </div>
                    </td>

                    <td className="text-50 js-lists-values-activity small">
                      <span className="chip chip-outline-secondary">
                        {record.isRemote ? "YES" : "NO"}
                      </span>
                    </td>
                    <td className="text-50 js-lists-values-activity small">
                      <span className="chip chip-outline-secondary">
                        {record.isEnabled ? "YES" : "NO"}
                      </span>
                    </td>
                    <td className="text-50 js-lists-values-activity small">
                      {moment(record.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="text-right pl-0">
                      <span className="text-50">
                        <i className="material-icons">more_vert</i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="card-footer p-8pt">
            <ul className="pagination justify-content-start pagination-xsm m-0">
              <li className="page-item disabled">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true" className="material-icons">
                    chevron_left
                  </span>
                  <span>Prev</span>
                </a>
              </li>
              <li className="page-item dropdown">
                <a
                  className="page-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  aria-label="Page"
                >
                  <span>1</span>
                </a>
                <div className="dropdown-menu">
                  <a href="" className="dropdown-item active">
                    1
                  </a>
                  <a href="" className="dropdown-item">
                    2
                  </a>
                  <a href="" className="dropdown-item">
                    3
                  </a>
                  <a href="" className="dropdown-item">
                    4
                  </a>
                  <a href="" className="dropdown-item">
                    5
                  </a>
                </div>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span>Next</span>
                  <span aria-hidden="true" className="material-icons">
                    chevron_right
                  </span>
                </a>
              </li>
            </ul>
          </div>
          {/* <!-- <div className="card-body bordet-top text-right">
    15 <span className="text-50">of 1,430</span> <a href="#" className="text-50"><i className="material-icons ml-1">arrow_forward</i></a>
    </div> --> */}
        </div>
      </div>
    ) : this.state.focusArea == "group" ? (
      <QfloGroupInfoCard group={this.state.selectedGroup} />
    ) : (
      <></>
    );
  }
}

export default QfloServiceGroupListInfoCard;
