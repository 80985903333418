import axios from "axios";
const Cookies = require("js-cookie");
// import { resolve } from "core-js/fn/promise";

// console.log("axios \n\n", axios.defaults);
// axios.defaults.adapter = require("axios/lib/adapters/http");
// export const BASE_URL = "http://localhost:8081/";

export const genesisClient = axios.create({
  baseURL: "https://tzgenesis.qflo.app/api/",
  useCredentials: true,
});

export const qfloClient = axios.create({
  baseURL: "https://tzsandbox.qflo.app/api/",
  useCredentials: true,
});

genesisClient.interceptors.request.use((req) => {
  // var access_token = Cookies.get("lika-Portal");
  var access_token = sessionStorage.getItem("genesis.qflo.app");
  req.headers = {
    ...req.headers,
    Authorization: `Bearer ${access_token}`,
  };
  // Important: request interceptors **must** return the request.
  return req;
});

qfloClient.interceptors.request.use((req) => {
  // var access_token = Cookies.get("lika-Portal");
  var access_token = sessionStorage.getItem("genesis.qflo.app");
  req.headers = {
    ...req.headers,
    Authorization: `Bearer ${access_token}`,
  };
  // Important: request interceptors **must** return the request.
  return req;
});

function updateToken(token) {
  Cookies.set("genesis.qflo.app", token);
  console.log("updating token");
  // $session.set("qflo", { access_token: token });
}
