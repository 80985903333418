import { combineReducers } from "redux";

import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import clientsReducer from "./clientsReducer";
import clientUserReducer from "./clientUserReducer";

const rootReducer = combineReducers({
  userData: userReducer,
  notificationData: notificationReducer,
  clientData: clientsReducer,
  clientUserData: clientUserReducer,
});

export default rootReducer;
