import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createContactPerson } from "../store/actions/clientActions";

class QfloContactPersonInfoOnboardingCard extends Component {
  state = {
    isNew: true,
    name: null,
    gender: "Male",
    email: null,
    number: null,
  };

  updateInfo = () => {};

  saveInfo = (e) => {
    e.preventDefault();

    let body = {
      name: this.state.name,
      gender: this.state.gender,
      email: this.state.email,
      number: this.state.number,
      organisation: this.props.client._id,
    };

    const exttoast = toast.loading("Setting up Contact person !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
    this.props
      .setupContactPerson(body)
      .then((res) => {
        // console.log(re);
        toast.update(exttoast, {
          render: "Complete",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.update(exttoast, {
          render: "Failed to setup Contact person !",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
  };

  render() {
    return this.state.isNew ? (
      <div className="page-section">
        <div className="page-separator">
          <div className="page-separator__text">Contact Person</div>
        </div>
        <form
          className="card--connect pb-32pt pb-lg-64pt"
          onSubmit={(e) => this.saveInfo(e)}
        >
          <div className="o-hidden mb-0">
            <div className="card-header bg-transparent"></div>
            <div className="list-group list-group-form">
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <div className="col-sm-12 media align-items-center">
                    <h5 className="text-uppercase mb-0">
                      Contact Person information
                    </h5>
                  </div>
                </div>
              </div>

              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      placeholder="Name ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div
                  role="group"
                  aria-labelledby="label-question"
                  className="m-0 form-group"
                >
                  <div className="form-row">
                    <label
                      id="label-question"
                      for="question"
                      className="col-md-3 col-form-label form-label"
                    >
                      Gender
                    </label>
                    <div className="col-md-9">
                      <select
                        id="select03"
                        data-toggle="select"
                        className="form-control"
                        value={this.state.gender}
                        onChange={(e) =>
                          this.setState({ gender: e.target.value })
                        }
                      >
                        <option selected value="Male">
                          Male
                        </option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Work Email
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder="Email ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Work Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.number}
                      onChange={(e) =>
                        this.setState({ number: e.target.value })
                      }
                      placeholder="Number ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div
                  className="form-group m-0"
                  role="group"
                  aria-labelledby="label-title"
                >
                  <div className="form-row">
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-accent"
                        style={{ float: "right" }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <div className="page-section">
        <div className="page-separator">
          <div className="page-separator__text">Contact Person</div>
        </div>

        <div className="list-group list-group-form">
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Profile photo
              </label>
              <div className="col-sm-9 media align-items-center">
                <a href="" className="media-left mr-16pt">
                  <img
                    src="/assets/images/people/110/guy-3.jpg"
                    alt="people"
                    width="56"
                    className="rounded-circle"
                  />
                </a>
                <div className="media-body">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile01"
                    />
                    <label className="custom-file-label" for="inputGroupFile01">
                      Choose file
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">Name</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value="Huma.com/alexander"
                  placeholder="Your profile name ..."
                />
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Work Email
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value="Huma.com/alexander"
                  placeholder="Your profile name ..."
                />
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Work Number
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value="Huma.com/alexander"
                  placeholder="Your profile name ..."
                />
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Gender
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value="Huma.com/alexander"
                  placeholder="Gender"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.clientData.client,
    contact: state.clientData.contact,
    user: state.userData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setupContactPerson: (data) => dispatch(createContactPerson(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QfloContactPersonInfoOnboardingCard);
