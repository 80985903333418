import React, { Component } from "react";

class QfloOrgInfoCard extends Component {
  state = {
    updating: false,
  };

  updateInfo = () => {};

  saveInfo = () => {};

  render() {
    return this.state.updating ? (
      <div className="page-section">
        <div className="page-separator">
          <div className="page-separator__text">Organisation</div>
        </div>
        <form className="card--connect pb-32pt pb-lg-64pt">
          <div className="card o-hidden mb-0">
            <div className="card-header bg-transparent">
              <h5 className="text-uppercase mb-0">Organisation information</h5>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    htmlFor="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Logo
                  </label>
                  <div className="col-md-9">
                    <div className="custom-file">
                      <input
                        type="file"
                        id="file"
                        className="custom-file-input"
                      />
                      <label htmlFor="file" className="custom-file-label">
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    htmlFor="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Name
                  </label>
                  <div className="col-md-9">
                    <input
                      id="org_name"
                      type="text"
                      placeholder="Organisation name"
                      value=""
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body ">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    htmlFor="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Industry
                  </label>
                  <div className="col-md-9">
                    <select
                      id="org_industry"
                      data-toggle="select"
                      className="form-control"
                    >
                      <option selected="">My first option</option>
                      <option>Another option</option>
                      <option>Third option is here</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body ">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    htmlFor="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Description
                  </label>
                  <div className="col-md-9">
                    <textarea
                      id="org_description"
                      placeholder="Describe organisation ..."
                      rows="4"
                      className="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    htmlFor="title"
                    className="col-md-12 col-form-label form-label"
                  >
                    Operating times
                  </label>
                  <div className="col-12">
                    <div className="row align-items-center">
                      <label
                        id="label-title"
                        htmlFor="title"
                        className="col-md-3 col-form-label form-label"
                      >
                        Weekdays
                      </label>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="flatpickrSample05"
                          >
                            Opening Time
                          </label>
                          <input
                            id="flatpickrSample05"
                            type="text"
                            className="form-control"
                            placeholder="Flatpickr time example"
                            data-toggle="flatpickr"
                            data-flatpickr-enable-time="true"
                            data-flatpickr-no-calendar="true"
                            data-flatpickr-alt-format="H:i"
                            data-flatpickr-date-format="H:i"
                            value="15:35"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="flatpickrSample05"
                          >
                            Closing Time
                          </label>
                          <input
                            id="flatpickrSample05"
                            type="text"
                            className="form-control"
                            placeholder="Flatpickr time example"
                            data-toggle="flatpickr"
                            data-flatpickr-enable-time="true"
                            data-flatpickr-no-calendar="true"
                            data-flatpickr-alt-format="H:i"
                            data-flatpickr-date-format="H:i"
                            value="15:35"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label
                        id="label-title"
                        htmlFor="title"
                        className="col-md-3 col-form-label form-label"
                      >
                        Weekends
                      </label>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="flatpickrSample05"
                          >
                            Opening Time
                          </label>
                          <input
                            id="flatpickrSample05"
                            type="text"
                            className="form-control"
                            placeholder="Flatpickr time example"
                            data-toggle="flatpickr"
                            data-flatpickr-enable-time="true"
                            data-flatpickr-no-calendar="true"
                            data-flatpickr-alt-format="H:i"
                            data-flatpickr-date-format="H:i"
                            value="15:35"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="flatpickrSample05"
                          >
                            Closing Time
                          </label>
                          <input
                            id="flatpickrSample05"
                            type="text"
                            className="form-control"
                            placeholder="Flatpickr time example"
                            data-toggle="flatpickr"
                            data-flatpickr-enable-time="true"
                            data-flatpickr-no-calendar="true"
                            data-flatpickr-alt-format="H:i"
                            data-flatpickr-date-format="H:i"
                            value="15:35"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    htmlFor="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Physical Address
                  </label>
                  <div className="col-md-9">
                    <input
                      id="org_name"
                      type="text"
                      placeholder="Organisation address"
                      value=""
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    htmlFor="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Number
                  </label>
                  <div className="col-md-9">
                    <input
                      id="org_name"
                      type="text"
                      placeholder="Organisation Number"
                      value=""
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    htmlFor="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Website url
                  </label>
                  <div className="col-md-9">
                    <input
                      id="org_name"
                      type="text"
                      placeholder="Organisation website"
                      value=""
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-accent"
                      style={{ float: "right" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <div className="page-section">
        <div className="col-md-12">
          <div className="card posts-card-popular">
            {this.props.client.logoUrl ? (
              <img
                src={this.props.client.logoUrl}
                alt=""
                className="card-img"
              />
            ) : (
              <img
                src="/assets/images/stories/256_rsz_euan-carmichael-666378-unsplash.jpg"
                alt=""
                className="card-img"
              />
            )}
          </div>
        </div>
        <div className="list-group list-group-form">
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Organisation Name
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>{this.props.client.organisationName}</p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Description
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>
                  {this.props.client.description
                    ? this.props.client.description
                    : "No description"}
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Industry
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>{this.props.client.industry}</p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Address
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>
                  {this.props.client.address
                    ? this.props.client.address
                    : "No address"}
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Website
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>
                  {this.props.client.website
                    ? this.props.client.website
                    : "No website"}
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Phone number
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>
                  {this.props.client.number
                    ? this.props.client.number
                    : "No number"}
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div
              className="form-group m-0"
              role="group"
              aria-labelledby="label-title"
            >
              <div className="form-row align-items-center">
                <label
                  id="label-title"
                  htmlFor="title"
                  className="col-md-12 col-form-label form-label"
                >
                  Operating times
                </label>
                <div className="col-12">
                  <div className="row align-items-center">
                    <label
                      id="label-title"
                      htmlFor="title"
                      className="col-md-3 col-form-label form-label"
                    >
                      Weekdays
                    </label>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="flatpickrSample05"
                        >
                          Opening Time
                        </label>
                        <p>
                          {
                            this.props.client.operatingTimes?.weekdays
                              .openingTime
                          }
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="flatpickrSample05"
                        >
                          Closing Time
                        </label>
                        <p>
                          {
                            this.props.client.operatingTimes?.weekdays
                              .closingTime
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <label
                      id="label-title"
                      htmlFor="title"
                      className="col-md-3 col-form-label form-label"
                    >
                      Weekends
                    </label>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="flatpickrSample05"
                        >
                          Opening Time
                        </label>
                        <p>
                          {
                            this.props.client.operatingTimes?.weekend
                              .openingTime
                          }
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="flatpickrSample05"
                        >
                          Closing Time
                        </label>
                        <p>
                          {
                            this.props.client.operatingTimes?.weekend
                              .closingTime
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QfloOrgInfoCard;
