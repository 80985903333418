import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Projects extends Component {
  state = {};

  selectProject = (projectId) => {
    // console.log(`/${projectId}/clients`)
    window.location.href = `/admin/${projectId}/clients`;
  };

  render() {
    return (
      <div
        className="container-fluid page__container"
        style={{ minHeight: "60vh", paddingTop: "2em", paddingBottom: "2em" }}
      >
        <div className="page-separator">
          <div className="page-separator__text">Projects</div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-md-4">
            <NavLink
              className="card posts-card-popular"
              to={`/admin/qflo/clients`}
              style={{ cursor: "pointer" }}
            >
              <img
                src="/assets/images/stories/256_rsz_euan-carmichael-666378-unsplash.jpg"
                alt=""
                className="card-img"
              />
              <div className="posts-card-popular__content">
                <div className="card-body d-flex align-items-center">
                  <div className="avatar-group flex">
                    <div
                      className="avatar avatar-xs"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Janell D."
                    >
                      <img
                        src="/assets/images/256_luke-porter-261779-unsplash.jpg"
                        alt="Avatar"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                  </div>
                  <a
                    style={{ textDecoration: "none" }}
                    className="d-flex align-items-center"
                    href=""
                  >
                    <i
                      className="material-icons mr-1"
                      style={{ fontSize: "inherit" }}
                    >
                      remove_red_eye
                    </i>{" "}
                    <small>327</small>
                  </a>
                </div>
                <div className="posts-card-popular__title card-body">
                  <h4 className="card-title m-0">QFlo</h4>
                </div>
              </div>
            </NavLink>
          </div>

          {/* <div className="col-sm-6 col-md-4">
                            <div className="card posts-card-popular"  style={{cursor: "pointer"}}>
                                <img src="/assets/images/stories/256_rsz_jared-rice-388260-unsplash.jpg"
                                        alt=""
                                        className="card-img"/>
                                <div className="posts-card-popular__content">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="avatar-group flex">
                                            <div className="avatar avatar-xs"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Janell D.">
                                                <a href=""><img src="/assets/images/256_michael-dam-258165-unsplash.jpg"
                                                            alt="Avatar"
                                                            className="avatar-img rounded-circle"/></a>
                                            </div>
                                        </div>
                                        <a style={{textDecoration: "none"}}
                                            className="d-flex align-items-center"
                                            href=""><i className="material-icons mr-1"
                                                style={{fontSize: "inherit"}}>remove_red_eye</i> <small>327</small></a>
                                    </div>
                                    <div className="posts-card-popular__title card-body">
                                        <h4 className="card-title m-0"><a href="">Project Name</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <div className="card posts-card-popular" style={{cursor: "pointer"}}>
                                <img src="/assets/images/stories/256_rsz_dex-ezekiel-761373-unsplash.jpg"
                                        alt=""
                                        className="card-img"/>
                                <div className="posts-card-popular__content">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="avatar-group flex">
                                            <div className="avatar avatar-xs"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Janell D.">
                                                <a href=""><img src="/assets/images/256_rsz_1andy-lee-642320-unsplash.jpg"
                                                            alt="Avatar"
                                                            className="avatar-img rounded-circle"/></a>
                                            </div>
                                        </div>
                                        <a style={{textDecoration: "none"}}
                                            className="d-flex align-items-center"
                                            href=""><i className="material-icons mr-1"
                                                style={{fontSize: "inherit"}}>remove_red_eye</i> <small>327</small></a>
                                    </div>
                                    <div className="posts-card-popular__title card-body">
                                        <h4 className="card-title m-0"><a href="">Project Name</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div> */}
        </div>
      </div>
    );
  }
}

export default Projects;
