import React, { Component } from "react";
class QFloMapMarker extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <div
        style={{
          width: "2rem",
          height: "2rem",
          background: "url('/assets/images/logo/Qflo-Q.png')",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      >
        {/* <div>Client Branches</div> */}
      </div>
    );
  }
}

export default QFloMapMarker;
