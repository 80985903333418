import {
  getClient,
  getClients,
  setupClient,
  setupContactPerson,
} from "../../api/QfloAPI/organisationAPIService";

export function getOrganisations() {
  return (dispatch) => {
    return getClients()
      .then((response) => {
        let clients;
        try {
          clients = response.data;
          return clients;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_CLIENTS",
            data: clients,
          });
          return Promise.resolve(clients);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function getOrganisation(client) {
  return (dispatch) => {
    return getClient(client)
      .then((response) => {
        let client;
        try {
          client = response.data;
          return client;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_CLIENT",
            data: client,
          });
          return Promise.resolve(client);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function createOrg(client) {
  return (dispatch) => {
    return setupClient(client)
      .then((response) => {
        let client;
        try {
          client = response.data;
          return client;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_CLIENT",
            data: client,
          });
          return Promise.resolve(client);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function createContactPerson(contactPerson) {
  return (dispatch) => {
    return setupContactPerson(contactPerson)
      .then((response) => {
        let client;
        try {
          contactPerson = response.data;
          return contactPerson;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_CONTACT_PERSON",
            data: client,
          });
          return Promise.resolve(contactPerson);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}
