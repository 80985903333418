import React, { Component } from "react";

class StaffNewInfoCard extends Component {

    constructor(props) {
        super(props);
      }

  state = {
    isNew:false
  }

  componentDidMount(){
      this.setState({isNew:this.props.isNew})
  }

  updateInfo = () => {

  }

  saveInfo = () =>{

  }
  
    render() {
  
      return (
        <>
          <div className="page-section">
              <h4>{
                  this.state.isNew ? 
                  "Add Account"
                  :
                  "Edit Account"
              }</h4>
            <div className="list-group list-group-form">
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">Your photo</label>
                        <div className="col-sm-9 media align-items-center">
                            <a href=""
                                className="media-left mr-16pt">
                                <img src="/assets/images/people/110/guy-3.jpg"
                                    alt="people"
                                    width="56"
                                    className="rounded-circle" />
                            </a>
                            <div className="media-body">
                                <div className="custom-file">
                                    <input type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile01"/>
                                    <label className="custom-file-label"
                                            htmlFor="inputGroupFile01">Choose file</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">First name</label>
                        <div className="col-sm-9">
                            <input type="text"
                                    className="form-control"
                                    value="Huma.com/alexander"
                                    placeholder="Your profile name ..."/>
                            <small className="form-text text-muted">Your profile name will be used as part of your public profile URL address.</small>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">Last name</label>
                        <div className="col-sm-9">
                            <input type="text"
                                    className="form-control"
                                    value="Huma.com/alexander"
                                    placeholder="Your profile name ..."/>
                            <small className="form-text text-muted">Your profile name will be used as part of your public profile URL address.</small>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">Email Address</label>
                        <div className="col-sm-9">
                            <input type="email"
                                    className="form-control"
                                    value="Huma.com/alexander"
                                    placeholder="Your work  email ..."/>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">Number</label>
                        <div className="col-sm-9">
                            <input type="text"
                                    className="form-control"
                                    value="Huma.com/alexander"
                                    placeholder="Work number ..."/>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div className="form-group row align-items-center mb-0">
                        <label className="col-form-label form-label col-sm-3">About you</label>
                        <div className="col-sm-9">
                            <textarea rows="3"
                                    className="form-control"
                                    placeholder="About you ..."></textarea>
                        </div>
                    </div>
                </div>
                <div className="list-group-item">
                    <div role="group"
                        aria-labelledby="label-question"
                        className="m-0 form-group">
                        <div className="form-row">
                            <label id="label-question"
                                htmlFor="question"
                                className="col-md-3 col-form-label form-label">Roles</label>
                            <div className="col-md-9">
                                <select id="service_locations"
                                        data-toggle="select"
                                        multiple
                                        value={["Administrator"]}
                                        onChange={() =>{}}
                                        className="form-control">
                                    <option>Administrator</option>
                                    <option >Account manager</option>
                                    <option>Tech Support</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div> 
        </div>
        </>
      );
  
    }
  
}

export default StaffNewInfoCard