import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { createOrg } from "../store/actions/clientActions";

class QfloOrgInfoOnboardingCard extends Component {
  state = {
    isNew: true,
    organisationName: null,
    imageInfo: { name: "" },
    industry: "Agriculture, Forestry, Fishing and Hunting",
    description: null,
    address: null,
    number: null,
    website: null,
    weekdaysOpeningTime: null,
    weekdaysClosingTime: null,
    weekendClosingTime: null,
    weekendOpeningTime: null,
    isRemoteEnabled: "YES",
  };

  updateInfo = () => {};

  saveInfo = (e) => {
    e.preventDefault();
    let body = new FormData();

    body.append("organisationName", this.state.organisationName);
    body.append("file", this.state.imageInfo.file, this.state.imageInfo.path);
    body.append("industry", this.state.industry);
    body.append("description", this.state.description);
    body.append("address", this.state.address);
    body.append("number", this.state.number);
    body.append("websiteUrl", this.state.website);
    body.append("weekdaysOpeningTime", this.state.weekdaysOpeningTime);
    body.append("weekdaysClosingTime", this.state.weekdaysClosingTime);
    body.append("weekendClosingTime", this.state.weekendClosingTime);
    body.append("weekendOpeningTime", this.state.weekendOpeningTime);
    body.append("isRemoteEnabled", this.state.isRemoteEnabled == "YES");
    body.append("createdBy", this.props.user._id);

    const exttoast = toast.loading("Setting up client !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
    this.props
      .setupClient(body)
      .then((res) => {
        // console.log(re);
        toast.update(exttoast, {
          render: "Complete",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.update(exttoast, {
          render: "Failed to setup client!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
  };

  handleImage = (file, ctx) => {
    if (!ctx) return;

    ctx.setState({
      imageInfo: {
        path: file.path,
        file: file,
        name: file.name,
      },
    });
    console.log(file);

    this.handleFilePreview(file);
  };

  handleFilePreview = (file) => {
    this.setState({ image: URL.createObjectURL(file) });
  };

  render() {
    return this.state.isNew ? (
      <div className="page-section">
        <div className="page-separator">
          <div className="page-separator__text">Organisation</div>
        </div>
        <form
          className="card--connect pb-32pt pb-lg-64pt"
          onSubmit={(e) => this.saveInfo(e)}
        >
          <div className="card o-hidden mb-0">
            <div className="card-header bg-transparent">
              <h5 className="text-uppercase mb-0">Organisation information</h5>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Logo
                  </label>
                  <div className="col-md-9">
                    <div className="custom-file">
                      <input
                        type="file"
                        id="file"
                        className="custom-file-input"
                        // value={this.state.imageInfo.name}
                        onChange={(e) =>
                          this.handleImage(e.target.files[0], this)
                        }
                      />
                      <label for="file" className="custom-file-label"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Name
                  </label>
                  <div className="col-md-9">
                    <input
                      id="org_name"
                      type="text"
                      placeholder="Organisation name"
                      value={this.state.organisationName}
                      onChange={(e) =>
                        this.setState({ organisationName: e.target.value })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body ">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Industry
                  </label>
                  <div className="col-md-9">
                    <select
                      id="org_industry"
                      data-toggle="select"
                      className="form-control"
                      value={this.state.industry}
                      onChange={(e) =>
                        this.setState({ industry: e.target.value })
                      }
                    >
                      <option
                        selected
                        value="Agriculture, Forestry, Fishing and Hunting"
                      >
                        Agriculture, Forestry, Fishing and Hunting
                      </option>
                      <option value="Arts, Entertainment and Recreation">
                        Arts, Entertainment and Recreation
                      </option>
                      <option value="Educational Services">
                        Educational Services
                      </option>
                      <option value="Accommodation and Food Services">
                        Accommodation and Food Services
                      </option>
                      <option
                        value="Administration, Business Support and Waste Management
                        Services"
                      >
                        Administration, Business Support and Waste Management
                        Services
                      </option>
                      <option value="Real Estate and Rental and Leasing">
                        Real Estate and Rental and Leasing
                      </option>
                      <option value="Information Technology">
                        Information Technology
                      </option>
                      <option value="Utilities">Utilities</option>
                      <option value="Finance and Insurance">
                        Finance and Insurance
                      </option>
                      <option value="Mining">Mining</option>
                      <option value="Other Services (except Public Administration)">
                        Other Services (except Public Administration)
                      </option>
                      <option value="Professional, Scientific and Technical Services">
                        Professional, Scientific and Technical Services
                      </option>
                      <option value="Transportation and Warehousing">
                        Transportation and Warehousing
                      </option>
                      <option value="Healthcare and Social Assistance">
                        Healthcare and Social Assistance
                      </option>
                      <option value="Wholesale Trade">Wholesale Trade</option>
                      <option value="Construction">Construction</option>
                      <option value="Retail Trade">Retail Trade</option>
                      <option value="Manufacturing">Manufacturing</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body ">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Is Remote enabled
                  </label>
                  <div className="col-md-9">
                    <select
                      id="org_industry"
                      data-toggle="select"
                      className="form-control"
                      value={this.state.isRemoteEnabled}
                      onChange={(e) =>
                        this.setState({ isRemoteEnabled: e.target.value })
                      }
                    >
                      <option selected>YES</option>
                      <option>NO</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body ">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Description
                  </label>
                  <div className="col-md-9">
                    <textarea
                      id="org_description"
                      placeholder="Describe organisation ..."
                      rows="4"
                      className="form-control"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-12 col-form-label form-label"
                  >
                    Operating times
                  </label>
                  <div className="col-12">
                    <div className="row align-items-center">
                      <label
                        id="label-title"
                        for="title"
                        className="col-md-3 col-form-label form-label"
                      >
                        Weekdays
                      </label>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label" for="flatpickrSample05">
                            Opening Time
                          </label>
                          <input
                            id="flatpickrSample05"
                            type="text"
                            className="form-control"
                            placeholder="Flatpickr time example"
                            data-toggle="flatpickr"
                            data-flatpickr-enable-time="true"
                            data-flatpickr-no-calendar="true"
                            data-flatpickr-alt-format="H:i"
                            data-flatpickr-date-format="H:i"
                            value={this.state.weekdaysOpeningTime}
                            onChange={(e) =>
                              this.setState({
                                weekdaysOpeningTime: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label" for="flatpickrSample05">
                            Closing Time
                          </label>
                          <input
                            id="flatpickrSample05"
                            type="text"
                            className="form-control"
                            placeholder="Flatpickr time example"
                            data-toggle="flatpickr"
                            data-flatpickr-enable-time="true"
                            data-flatpickr-no-calendar="true"
                            data-flatpickr-alt-format="H:i"
                            data-flatpickr-date-format="H:i"
                            value={this.state.weekdaysClosingTime}
                            onChange={(e) =>
                              this.setState({
                                weekdaysClosingTime: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label
                        id="label-title"
                        for="title"
                        className="col-md-3 col-form-label form-label"
                      >
                        Weekends
                      </label>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label" for="flatpickrSample05">
                            Opening Time
                          </label>
                          <input
                            id="flatpickrSample05"
                            type="text"
                            className="form-control"
                            placeholder="Flatpickr time example"
                            data-toggle="flatpickr"
                            data-flatpickr-enable-time="true"
                            data-flatpickr-no-calendar="true"
                            data-flatpickr-alt-format="H:i"
                            data-flatpickr-date-format="H:i"
                            value={this.state.weekendOpeningTime}
                            onChange={(e) =>
                              this.setState({
                                weekendOpeningTime: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label" for="flatpickrSample05">
                            Closing Time
                          </label>
                          <input
                            id="flatpickrSample05"
                            type="text"
                            className="form-control"
                            placeholder="Flatpickr time example"
                            data-toggle="flatpickr"
                            data-flatpickr-enable-time="true"
                            data-flatpickr-no-calendar="true"
                            data-flatpickr-alt-format="H:i"
                            data-flatpickr-date-format="H:i"
                            value={this.state.weekendClosingTime}
                            onChange={(e) =>
                              this.setState({
                                weekendClosingTime: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Physical Address
                  </label>
                  <div className="col-md-9">
                    <input
                      id="org_name"
                      type="text"
                      placeholder="Organisation address"
                      value={this.state.address}
                      onChange={(e) =>
                        this.setState({ address: e.target.value })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Number
                  </label>
                  <div className="col-md-9">
                    <input
                      id="org_name"
                      type="text"
                      placeholder="Organisation Number"
                      value={this.state.number}
                      onChange={(e) =>
                        this.setState({ number: e.target.value })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row align-items-center">
                  <label
                    id="label-title"
                    for="title"
                    className="col-md-3 col-form-label form-label"
                  >
                    Website url
                  </label>
                  <div className="col-md-9">
                    <input
                      id="org_name"
                      type="text"
                      placeholder="Organisation website"
                      value={this.state.website}
                      onChange={(e) =>
                        this.setState({ website: e.target.value })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="form-group m-0"
                role="group"
                aria-labelledby="label-title"
              >
                <div className="form-row">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-accent"
                      style={{ float: "right" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <div className="page-section">
        <div className="col-md-12">
          <div className="card posts-card-popular">
            <img
              src="/assets/images/stories/256_rsz_euan-carmichael-666378-unsplash.jpg"
              alt=""
              className="card-img"
            />
          </div>
        </div>
        <div className="list-group list-group-form">
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Organisation Name
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>Organisation name</p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Description
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequuntur assumenda tenetur excepturi odio mollitia quas
                  cupiditate temporibus explicabo ipsum! Aperiam quidem velit,
                  dolore suscipit perspiciatis eligendi placeat ratione
                  voluptates nostrum.
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Industry
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>Organisation Industry</p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Address
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>Organisation Address</p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Website
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>http://google.com</p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Phone number
              </label>
              <div className="col-sm-9 media align-items-center">
                <p>(267) 777 777 77</p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div
              className="form-group m-0"
              role="group"
              aria-labelledby="label-title"
            >
              <div className="form-row align-items-center">
                <label
                  id="label-title"
                  for="title"
                  className="col-md-12 col-form-label form-label"
                >
                  Operating times
                </label>
                <div className="col-12">
                  <div className="row align-items-center">
                    <label
                      id="label-title"
                      for="title"
                      className="col-md-3 col-form-label form-label"
                    >
                      Weekdays
                    </label>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" for="flatpickrSample05">
                          Opening Time
                        </label>
                        <p>15:30</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" for="flatpickrSample05">
                          Closing Time
                        </label>
                        <p>15:30</p>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <label
                      id="label-title"
                      for="title"
                      className="col-md-3 col-form-label form-label"
                    >
                      Weekends
                    </label>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" for="flatpickrSample05">
                          Opening Time
                        </label>
                        <p>15:30</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" for="flatpickrSample05">
                          Closing Time
                        </label>
                        <p>15:30</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.clientData.client,
    user: state.userData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setupClient: (data) => dispatch(createOrg(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QfloOrgInfoOnboardingCard);
