import React, { Component } from "react";

class QfloDepartmentInfoCard extends Component {
  state = {
    isUpdating: false,
    department: {},
  };

  updateInfo = () => {};

  saveInfo = () => {};

  componentDidMount() {
    this.setState({ department: this.props.department });
  }
  render() {
    return (
      <>
        <div className="page-section">
          <div className="page-separator">
            <div className="page-separator__text">Department</div>
          </div>
          <div className="list-group list-group-form">
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Name
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.department.sectionName}
                    placeholder="Department name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QfloDepartmentInfoCard;
