import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { getOrganisations } from "../store/actions/clientActions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

class Clients extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    project: "",
  };

  componentDidMount() {
    // let project = this.props.history.location.pathname.split("/")[2];

    // if (project) {
    //   this.setState({ project: project });
    // }

    // TODO: GET CLIENTS

    const exttoast = toast.loading("Getting clients !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
    this.props
      .getClients()
      .then((res) => {
        // console.log(re);
        toast.update(exttoast, {
          render: "Complete",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.update(exttoast, {
          render: "Failed to get clients!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
  }

  render() {
    return (
      <>
        <div className="border-bottom-2 py-32pt position-relative z-1">
          <div className="container-fluid page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
            <div className="flex d-flex flex-column flex-sm-row align-items-center">
              <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                <h2 className="mb-0">Clients</h2>

                <ol className="breadcrumb p-0 m-0">
                  <li className="breadcrumb-item">
                    <NavLink to="/admin">Home</NavLink>
                  </li>

                  <li className="breadcrumb-item">
                    <NavLink to="/admin/qflo/clients">
                      {this.state.project}
                    </NavLink>
                  </li>

                  <li className="breadcrumb-item active">Clients</li>
                </ol>
              </div>
            </div>
            <div className="row" role="tablist">
              <div className="col-auto border-left">
                <NavLink
                  to="/admin/qflo/clients/onboarding"
                  className="btn btn-accent"
                >
                  Onboard Client
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid page__container"
          style={{ minHeight: "60vh", paddingTop: "2em", paddingBottom: "2em" }}
        >
          <div className="row">
            <div className="col-lg-12 pr-lg-0">
              <div className="page-section">
                <div className="page-separator">
                  <div className="page-separator__text">Clients</div>
                </div>

                <div className="card mb-0">
                  <div
                    className="table-responsive"
                    data-toggle="lists"
                    data-lists-sort-by="js-lists-values-employee-name"
                    data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'
                  >
                    <div className="card-header">
                      <form className="form-inline">
                        <label
                          className="mr-sm-2 form-label"
                          htmlFor="inlineFormFilterBy"
                        >
                          Filter by:
                        </label>
                        <input
                          type="text"
                          className="form-control search mb-2 mr-sm-2 mb-sm-0"
                          id="inlineFormFilterBy"
                          placeholder="Search ..."
                        />

                        <label className="sr-only" htmlFor="inlineFormRole">
                          Staus
                        </label>
                        <select
                          id="inlineFormRole"
                          className="custom-select mb-2 mr-sm-2 mb-sm-0"
                        >
                          <option value="All Roles">Active</option>
                        </select>

                        <div className="ml-auto mb-2 mb-sm-0 custom-control-inline mr-0">
                          <label className="form-label mb-0" htmlFor="active">
                            Active
                          </label>
                          <div className="custom-control custom-checkbox-toggle ml-8pt">
                            <input
                              checked=""
                              type="checkbox"
                              id="active"
                              className="custom-control-input"
                              onChange={() => {}}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="active"
                            >
                              Active
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>

                    <table className="table mb-0 thead-border-top-0 table-nowrap">
                      <thead>
                        <tr>
                          <th>
                            <a
                              href=""
                              className="sort"
                              data-sort="js-lists-values-employee-name"
                            >
                              Client Name
                            </a>
                          </th>

                          <th className="text-center" style={{ width: "48px" }}>
                            <a
                              href=""
                              className="sort"
                              data-sort="js-lists-values-projects"
                            >
                              Country
                            </a>
                          </th>

                          <th style={{ width: "37px" }}>Status</th>

                          <th style={{ width: "120px" }}>
                            <a
                              href=""
                              className="sort"
                              data-sort="js-lists-values-activity"
                            >
                              Onboarding Date
                            </a>
                          </th>

                          <th style={{ width: "24px" }} className="pl-0"></th>
                        </tr>
                      </thead>
                      <tbody className="list" id="staff">
                        {this.props.clients.map((client, idx) => (
                          <tr key={idx} className="selected">
                            <td>
                              <div
                                className="media flex-nowrap align-items-center"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {client.logoUrl ? (
                                  <div className="avatar avatar-32pt mr-8pt">
                                    <img
                                      src={client.logoUrl}
                                      alt="Avatar"
                                      className="avatar-img rounded-circle"
                                    />
                                  </div>
                                ) : (
                                  <div className="avatar avatar-32pt mr-8pt">
                                    <span className="avatar-title rounded-circle">
                                      {client.organisationName[0]}
                                    </span>
                                  </div>
                                )}

                                <div className="media-body">
                                  <NavLink
                                    to={`/admin/qflo/clients/${client._id}`}
                                    className="d-flex flex-column"
                                  >
                                    <p className="mb-0">
                                      <strong className="js-lists-values-employee-name">
                                        {client.organisationName}
                                      </strong>
                                    </p>
                                    <small className="js-lists-values-employee-email text-50">
                                      Cient Website or number
                                    </small>
                                  </NavLink>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                <span className="text-70">
                                  <span className="js-lists-values-employer-name">
                                    Botswana
                                  </span>
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="chip chip-outline-secondary">
                                {client.active ? "Active" : "Disabled"}
                              </span>
                            </td>

                            <td className="text-50 js-lists-values-activity small">
                              {moment(client.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td className="text-right pl-0">
                              <span className="text-50">
                                <i className="material-icons">more_vert</i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="card-footer p-8pt">
                    <ul className="pagination justify-content-start pagination-xsm m-0">
                      <li className="page-item disabled">
                        <a className="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true" className="material-icons">
                            chevron_left
                          </span>
                          <span>Prev</span>
                        </a>
                      </li>
                      <li className="page-item dropdown">
                        <a
                          className="page-link dropdown-toggle"
                          data-toggle="dropdown"
                          href="#"
                          aria-label="Page"
                        >
                          <span>1</span>
                        </a>
                        <div className="dropdown-menu">
                          <a href="" className="dropdown-item active">
                            1
                          </a>
                          <a href="" className="dropdown-item">
                            2
                          </a>
                          <a href="" className="dropdown-item">
                            3
                          </a>
                          <a href="" className="dropdown-item">
                            4
                          </a>
                          <a href="" className="dropdown-item">
                            5
                          </a>
                        </div>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next">
                          <span>Next</span>
                          <span aria-hidden="true" className="material-icons">
                            chevron_right
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- <div className="card-body bordet-top text-right">
            15 <span className="text-50">of 1,430</span> <a href="#" className="text-50"><i className="material-icons ml-1">arrow_forward</i></a>
        </div> --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clientData.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClients: () => dispatch(getOrganisations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
