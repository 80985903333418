import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import StaffInfoCard from "../components/StaffInfoCard";
import StaffNewInfoCard from "../components/StaffNewInfoCard";
import StaffPasswordResetCard from "../components/StaffPasswordResetCard";

class StaffInfoContainer extends Component {

    state = {
      targetUser:{},
      focusArea:"info",
    }
  
  
    render() {
  
      return (
        <>
          <div className="border-bottom-2 py-32pt position-relative z-1">
            <div className="container-fluid page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                <div className="flex d-flex flex-column flex-sm-row align-items-center">

                    <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                        <h2 className="mb-0">User email</h2>

                        <ol className="breadcrumb p-0 m-0">
                            <li className="breadcrumb-item"><NavLink to="/admin">Home</NavLink></li>

                            <li className="breadcrumb-item">

                                <NavLink to="/admin/users">Staff</NavLink>

                            </li>
                            <li className="breadcrumb-item active">

                                <NavLink to={`/admin/users/${this.state.targetUser._id}`}>{this.state.targetUser.email}</NavLink>

                            </li>
                            

                        </ol>

                    </div>
                </div>
                <div className="row"
                    role="tablist">
                    <div className="col-auto border-left">
                        <a className="btn btn-accent">Disable Account</a>
                    </div>
                </div>

            </div>
          </div>
          <div className="container-fluid page__container" style={{minHeight: "60vh", paddingTop:"2em",  paddingbottom:"2em"}}>
              <div className="row">
                  <div className="col-lg-9 pr-lg-0">
                    {
                      this.state.focusArea == "info" ?
                        <StaffInfoCard user={this.state.targetUser}/>
                      :
                      this.state.focusArea == "edit" ? 
                        <StaffNewInfoCard isNew={false} user={this.state.targetUser}/>
                      :
                      this.state.focusArea == "passwordReset" ?
                        <StaffPasswordResetCard user={this.state.targetUser}/>
                      :
                        <StaffInfoCard user={this.state.targetUser}/>
                    }
                      
                  </div>
                  <div className="col-lg-3 page-nav">
                      <div className="page-section pt-lg-112pt">
                          <nav className="nav page-nav__menu">
                              <a onClick={() => {this.setState({focusArea:"info"})}} className="nav-link"  style={{cursor:"pointer"}}>Basic Information</a>
                              <a onClick={() => {this.setState({focusArea:"edit"})}} className="nav-link"  style={{cursor:"pointer"}}>Edit Information</a>
                              <a onClick={() => {this.setState({focusArea:"passwordReset"})}} className="nav-link"  style={{cursor:"pointer"}}>Change Password</a>
                          </nav>
                          {
                            this.state.focusArea == "edit" ?
                            <div className="page-nav__content">
                              <button type="submit"
                                      className="btn btn-accent">Save changes</button>
                            </div>
                          :
                          this.state.focusArea == "passwordReset" ?
                            <div className="page-nav__content">
                              <button type="submit"
                                      className="btn btn-accent">Save changes</button>
                            </div>
                          :
                          <></>
                          }
                          
                      </div>
                  </div>
              </div>
          </div>

        </>
      );
  
    }
  
}

export default StaffInfoContainer