import React, { Component } from "react";

class Footer extends Component {
  state = {};

  render() {
    return (
      <div className="js-fix-footer footer border-top-2">
        <div className="container-fluid page__container page-section d-flex flex-column">
          <p className="text-70 brand mb-24pt">
            <img
              className="brand-icon"
              src="/assets/images/logo/Qflo-Q.png"
              width="30"
              alt="Huma"
            />{" "}
            Genesis
          </p>
          {/* <p className="measure-lead-max text-muted mb-0 small"></p> */}
        </div>
        <div className="pb-16pt pb-lg-24pt">
          <div className="container-fluid page__container">
            <div className="bg-dark rounded page-section py-lg-32pt px-16pt px-lg-24pt">
              <div className="row">
                <div className="col-md-2 col-sm-4 mb-24pt mb-md-0">
                  <p className="text-white-70 mb-8pt">
                    <strong>Follow us</strong>
                  </p>
                  <nav className="nav nav-links nav--flush">
                    <a href="#" className="nav-link mr-8pt">
                      <img
                        src="/assets/images/icon/footer/facebook-square@2x.png"
                        width="24"
                        height="24"
                        alt="Facebook"
                      />
                    </a>
                    <a href="#" className="nav-link mr-8pt">
                      <img
                        src="/assets/images/icon/footer/twitter-square@2x.png"
                        width="24"
                        height="24"
                        alt="Twitter"
                      />
                    </a>
                    <a href="#" className="nav-link mr-8pt">
                      <img
                        src="/assets/images/icon/footer/vimeo-square@2x.png"
                        width="24"
                        height="24"
                        alt="Vimeo"
                      />
                    </a>
                    {/* <!-- <a href="#" className="nav-link"><img src="/assets/images/icon/footer/youtube-square@2x.png" width="24" height="24" alt="YouTube"></a> --> */}
                  </nav>
                </div>
                <div className="col-md-6 col-sm-4 mb-24pt mb-md-0 d-flex align-items-center"></div>
                <div className="col-md-4 text-md-right">
                  <p className="mb-8pt d-flex align-items-md-center justify-content-md-end">
                    <a href="" className="text-white-70 text-underline mr-16pt">
                      Terms
                    </a>
                    <a href="" className="text-white-70 text-underline">
                      Privacy policy
                    </a>
                  </p>
                  <p className="text-white-50 small mb-0">
                    Copyright 2019 &copy; All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
