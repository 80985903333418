import React, { Component } from "react";

class QfloServiceGroupInfoCard extends Component {
  state = {
    isUpdating: false,
    group: {},
  };

  updateInfo = () => {};

  saveInfo = () => {};

  componentDidMount() {
    this.setState({ group: this.props.group });
  }

  render() {
    return (
      <>
        <div className="page-section">
          <div className="page-separator">
            <div className="page-separator__text">Service Group</div>
          </div>
          <div className="list-group list-group-form">
            <div className="list-group-item">
              <div
                role="group"
                aria-labelledby="label-question"
                className="m-0 form-group"
              >
                <div className="form-row">
                  <label
                    id="label-question"
                    for="question"
                    className="col-md-3 col-form-label form-label"
                  >
                    Name
                  </label>
                  <div className="col-md-9">
                    <input
                      id="sgroup_name"
                      type="text"
                      placeholder="name"
                      value={this.state.group.name}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div
                role="group"
                aria-labelledby="label-question"
                className="m-0 form-group"
              >
                <div className="form-row">
                  <label
                    id="label-question"
                    for="question"
                    className="col-md-3 col-form-label form-label"
                  >
                    Prefix
                  </label>
                  <div className="col-md-9">
                    <input
                      id="sgroup_prefix"
                      type="text"
                      placeholder="prefix"
                      value={this.state.group.prefix}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div
                role="group"
                aria-labelledby="label-question"
                className="m-0 form-group"
              >
                <div className="form-row">
                  <label
                    id="label-question"
                    for="question"
                    className="col-md-3 col-form-label form-label"
                  >
                    Is Remote
                  </label>
                  <div className="col-md-9">
                    <select
                      id="service_locations"
                      data-toggle="select"
                      className="form-control"
                    >
                      {this.state.group.isRemote ? (
                        <option selected>YES</option>
                      ) : (
                        <option selected>NO</option>
                      )}
                      <option>NO</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div
                role="group"
                aria-labelledby="label-question"
                className="m-0 form-group"
              >
                <div className="form-row">
                  <label
                    id="label-question"
                    for="question"
                    className="col-md-3 col-form-label form-label"
                  >
                    Is Enabled
                  </label>
                  <div className="col-md-9">
                    <select
                      id="service_locations"
                      data-toggle="select"
                      className="form-control"
                    >
                      {this.state.group.enabled ? (
                        <option selected>YES</option>
                      ) : (
                        <option selected>NO</option>
                      )}
                      <option>NO</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QfloServiceGroupInfoCard;
