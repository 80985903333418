import { getUser, login } from "../../api/Genesis/AuthAPIService";

export function getCurrentUser() {
  return (dispatch) => {
    return getUser()
      .then((response) => {
        let user;
        try {
          user =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
          return user;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_CURRENT_USER",
            data: user,
          });
          return Promise.resolve(user);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function setCurrentUser(user) {
  return (dispatch) => {
    return dispatch({
      type: "GET_CURRENT_USER",
      data: user,
    });
  };
}
