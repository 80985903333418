import React, { Component } from "react";

class QfloServiceGroupInfoOnboardingCard extends Component {

    state = {
        isNew:true,
        revealForm:false,
        services:[
            {
                serviceName:"Service Name",
                prefix: "P",
                serviceQuota:200,
                section:"department 1",
                branches:[
                    {
                        branchName:"Branch Name",
                        location: "Location"
                    }
                ]

            }
        ]
    }
  
  
    updateInfo = () => {

    }

    saveInfo = () =>{

    }

    updateService = (service) =>{
        this.setState({isNew:false, revealForm:true})
    }

    showForm =  () => {
        this.setState({revealForm:true})
    }

    render() {
  
      return (
        <>
            <div className="page-separator">
                <div className="page-separator__text">Service Groups</div>
            </div>
            <div className="row align-items-center mb-24pt mb-lg-32pt">
                {
                    this.state.services.map( (service, idx) => (
                        <div onClick={() => this.updateService(service)} className="col-3 mb-3 position-relative" style={{cursor:"pointer"}}>
                            <span className="d-flex flex-column border-1 rounded bg-alt px-16pt py-8pt lh-1"
                                href="">
                                <small><strong>Name</strong></small>
                                <small className="text-50">prefix</small>
                            </span>
                            <span
                                className="position-close-18pt">
                                <i className="material-icons text-50 icon-16pt">close</i>
                            </span>
                        </div>
                    ))
                }
                <span onClick={() => this.showForm()} className="col-3 mb-3 d-flex flex-column border-1 rounded bg-alt px-16pt py-8pt lh-1 text-center" style={{cursor:"pointer"}}>
                    <i className="material-icons text-50 icon-16pt">add</i>
                    <small><strong>New Service Group</strong></small>
                </span>
            </div>

            {
                this.state.revealForm ? 
                    <form className="card--connect pb-32pt pb-lg-64pt">
                                        
                        <div className="card o-hidden mb-0">
                            <div className="card-header bg-transparent">
                                <h5 className="text-uppercase mb-0">Service Group Information</h5>
                            </div>
                            <div className="list-group-item">
                                <div role="group"
                                    aria-labelledby="label-question"
                                    className="m-0 form-group">
                                    <div className="form-row">
                                        <label id="label-question"
                                            for="question"
                                            className="col-md-3 col-form-label form-label">Name</label>
                                        <div className="col-md-9">
                                            <input id="sgroup_name"
                                                    type="text"
                                                    placeholder="name"
                                                    value=""
                                                    className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-group-item">
                                <div role="group"
                                    aria-labelledby="label-question"
                                    className="m-0 form-group">
                                    <div className="form-row">
                                        <label id="label-question"
                                            for="question"
                                            className="col-md-3 col-form-label form-label">Prefix</label>
                                        <div className="col-md-9">
                                            <input id="sgroup_prefix"
                                                    type="text"
                                                    placeholder="prefix"
                                                    value=""
                                                    className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-group-item">
                                <div role="group"
                                    aria-labelledby="label-question"
                                    className="m-0 form-group">
                                    <div className="form-row">
                                        <label id="label-question"
                                            for="question"
                                            className="col-md-3 col-form-label form-label">Services</label>
                                        <div className="col-md-9">
                                            <select id="select03"
                                                    data-toggle="select"
                                                    multiple
                                                    className="form-control">
                                                <option selected="">CSS</option>
                                                <option selected="">HTML</option>
                                                <option>JavaScript</option>
                                                <option>Bootstrap</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="list-group-item col-12">
                                <button type="button"
                                        className="btn btn-accent" style={{float: "right"}}>Submit</button>
                            </div>
                        </div>
                    </form>
                
            :
                <></>
            }
        </>
      );
  
    }
  
}

export default QfloServiceGroupInfoOnboardingCard