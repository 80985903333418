import React, { Component } from "react";
import { Route, Routes, NavLink } from "react-router-dom";
import { createBrowserHistory } from "history";
import Footer from "../components/Footer";
import Clients from "./Clients";
import Projects from "./Projects";
import ClientOnboarding from "./ClientOnboarding";
import Client from "./Client";
import Staff from "./Staff";
import StaffInfoContainer from "./StaffInfoContainer";
import { connect } from "react-redux";
import { getCurrentUser } from "../store/actions/authActions";

class AdminWrapper extends Component {
  state = {};

  componentDidMount() {
    this.props.me();
  }
  render() {
    const history = createBrowserHistory();
    return (
      <>
        {/* <!-- Header --> */}
        <div className="mdk-drawer-layout__content page-content">
          <div
            className="navbar navbar-expand navbar-shadow px-0  pl-lg-16pt navbar-light bg-body"
            id="default-navbar"
            data-primary
          >
            {/* <!-- Navbar toggler --> */}
            <button
              className="navbar-toggler d-block d-lg-none rounded-0"
              type="button"
              data-toggle="sidebar"
            >
              <span className="material-icons">menu</span>
            </button>

            {/* <!-- Navbar Brand --> */}
            <a href="index.html" className="navbar-brand mr-16pt d-lg-none">
              <img
                className="navbar-brand-icon mr-0 mr-lg-8pt"
                src="/assets/images/logo/accent-red-100@2x.png"
                width="32"
                alt="Genesis"
              />
              <span className="d-none d-lg-block">Genesis</span>
            </a>

            {/* <!-- <button className="btn navbar-btn mr-16pt" data-toggle="modal" data-target="#apps">Apps <i className="material-icons">arrow_drop_down</i></button> --> */}

            <form
              className="search-form navbar-search d-none d-md-flex mr-16pt"
              action="index.html"
            >
              <button className="btn" type="submit">
                <i className="material-icons">search</i>
              </button>
              <input
                type="text"
                className="form-control"
                placeholder="Search ..."
              />
            </form>

            <div className="flex"></div>
            <div className="nav navbar-nav flex-nowrap d-flex ml-0 mr-16pt">
              <div className="nav-item dropdown d-none d-sm-flex">
                <a
                  href="#"
                  className="nav-link d-flex align-items-center dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <img
                    width="32"
                    height="32"
                    className="rounded-circle mr-8pt"
                    src="/assets/images/people/50/guy-3.jpg"
                    alt="account"
                  />
                  <span className="flex d-flex flex-column mr-8pt">
                    <span className="navbar-text-100">
                      {this.props.user.firstname} {this.props.user.lastname}
                    </span>
                    <small className="navbar-text-50">
                      {this.props.user.role}
                    </small>
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-header">
                    <strong>Account</strong>
                  </div>
                  <a className="dropdown-item" href="edit-account.html">
                    Edit Account
                  </a>
                  <a className="dropdown-item" href="login.html">
                    Logout
                  </a>
                </div>
              </div>

              {/* <!-- Notifications dropdown --> */}

              {/* <!-- // END Notifications dropdown --> */}

              {/* <!-- Notifications dropdown --> */}
              <div className="nav-item ml-16pt dropdown dropdown-notifications">
                <button
                  className="nav-link btn-flush dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  data-dropdown-disable-document-scroll
                  data-caret="false"
                >
                  <i className="material-icons icon-24pt">mail_outline</i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  {/* <div data-perfect-scrollbar className="position-relative">
                    <div className="dropdown-header">
                      <strong>Messages</strong>
                    </div>
                    <div className="list-group list-group-flush mb-0">
                      <a
                        href=""
                        className="list-group-item list-group-item-action unread"
                      >
                        <span className="d-flex align-items-center mb-1">
                          <small className="text-black-50">5 minutes ago</small>

                          <span className="ml-auto unread-indicator bg-accent"></span>
                        </span>
                        <span className="d-flex">
                          <span className="avatar avatar-xs mr-2">
                            <img
                              src="/assets/images/people/110/woman-5.jpg"
                              alt="people"
                              className="avatar-img rounded-circle"
                            />
                          </span>
                          <span className="flex d-flex flex-column">
                            <strong className="text-black-100">Michelle</strong>
                            <span className="text-black-70">
                              Clients loved the new design.
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <!-- // END Notifications dropdown --> */}
            </div>

            <div className="dropdown border-left-2 navbar-border">
              <button
                className="navbar-toggler navbar-toggler-custom d-block"
                type="button"
                data-toggle="dropdown"
                data-caret="false"
              >
                <span className="material-icons">business_center</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {/* <div className="dropdown-header">
                  <strong>Select profile</strong>
                </div> */}
                {/* <a
                  href=""
                  className="dropdown-item active d-flex align-items-center"
                >
                  <div className="avatar avatar-sm mr-8pt">
                    <span className="avatar-title rounded bg-primary">AD</span>
                  </div>

                  <small className="ml-4pt flex">
                    <span className="d-flex flex-column">
                      <strong className="text-black-100">Administrator</strong>
                      {/* <!-- <span className="text-black-50">Administrator</span> --> 
                    </span>
                  </small>
                </a>
                <a href="" className="dropdown-item d-flex align-items-center">
                  <div className="avatar avatar-sm mr-8pt">
                    <span className="avatar-title rounded bg-accent">AM</span>
                  </div>

                  <small className="ml-4pt flex">
                    <span className="d-flex flex-column">
                      <strong className="text-black-100">
                        Account Manager
                      </strong>
                      {/* <!-- <span className="text-black-50">Publisher</span> -->
                    </span>
                  </small>
                </a>
                <a href="" className="dropdown-item d-flex align-items-center">
                  <div className="avatar avatar-sm mr-8pt">
                    <span className="avatar-title rounded bg-accent">TS</span>
                  </div>

                  <small className="ml-4pt flex">
                    <span className="d-flex flex-column">
                      <strong className="text-black-100">Tech Support</strong>
                      {/* <!-- <span className="text-black-50">Publisher</span> --> 
                    </span>
                  </small>
                </a> */}
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/" element={<Projects />} />
            <Route
              path="/:id/clients/"
              element={<Clients history={history} />}
            />
            <Route
              path="/:id/clients/:id"
              element={<Client history={history} />}
            />
            <Route
              path="/qflo/clients/onboarding"
              element={<ClientOnboarding history={history} />}
            />
            <Route path="/users" element={<Staff history={history} />} />
            <Route
              path="/users/:id"
              element={<StaffInfoContainer history={history} />}
            />
          </Routes>
          <Footer />
        </div>

        {/* <!-- // END Header --> */}

        {/* <!-- drawer --> */}
        <div className="mdk-drawer js-mdk-drawer" id="default-drawer">
          <div className="mdk-drawer__content">
            <div
              className="sidebar sidebar-dark sidebar-left"
              data-perfect-scrollbar
            >
              {/* <!-- Navbar toggler --> */}
              <a
                href="compact-index.html"
                className="navbar-toggler navbar-toggler-right navbar-toggler-custom d-flex align-items-center justify-content-center position-absolute right-0 top-0"
                data-toggle="tooltip"
                data-title="Switch to Compact Vertical Layout"
                data-placement="right"
                data-boundary="window"
              >
                <span className="material-icons">sync_alt</span>
              </a>

              <a href="index.html" className="sidebar-brand ">
                <img
                  className="sidebar-brand-icon"
                  src="/assets/images/logo/accent-red-100@2x.png"
                  alt="Huma"
                />
                <span>Genesis</span>
              </a>

              <div className="sidebar-account mx-16pt mb-16pt dropdown">
                <a
                  href="#"
                  className="nav-link d-flex align-items-center dropdown-toggle"
                  data-toggle="dropdown"
                  data-caret="false"
                >
                  <img
                    width="32"
                    height="32"
                    className="rounded-circle mr-8pt"
                    src="/assets/images/people/50/guy-3.jpg"
                    alt="account"
                  />
                  <span className="flex d-flex flex-column mr-8pt">
                    <span className="text-black-100">
                      {this.props.user.firstname + " "}
                      {this.props.user.lastname}
                    </span>
                    <small className="text-black-50">
                      {this.props.user.role}
                    </small>
                  </span>
                  <i className="material-icons text-black-20 icon-16pt">
                    keyboard_arrow_down
                  </i>
                </a>
                <div className="dropdown-menu dropdown-menu-full dropdown-menu-caret-center">
                  <div className="dropdown-header">
                    <strong>Account</strong>
                  </div>
                  <a className="dropdown-item" href="edit-account.html">
                    Edit Account
                  </a>
                  <a className="dropdown-item" href="login.html">
                    Logout
                  </a>
                  <div className="dropdown-divider"></div>
                  <div className="dropdown-header">
                    <strong>Select profile</strong>
                  </div>
                  <a
                    href=""
                    className="dropdown-item active d-flex align-items-center"
                  >
                    <div className="avatar avatar-sm mr-8pt">
                      <span className="avatar-title rounded bg-primary">
                        AD
                      </span>
                    </div>

                    <small className="ml-4pt flex">
                      <span className="d-flex flex-column">
                        <strong className="text-black-100">
                          Administrator
                        </strong>
                        {/* <!-- <span className="text-black-50">Administrator</span> --> */}
                      </span>
                    </small>
                  </a>
                  <a
                    href=""
                    className="dropdown-item d-flex align-items-center"
                  >
                    <div className="avatar avatar-sm mr-8pt">
                      <span className="avatar-title rounded bg-accent">AM</span>
                    </div>

                    <small className="ml-4pt flex">
                      <span className="d-flex flex-column">
                        <strong className="text-black-100">
                          Account Manager
                        </strong>
                        {/* <!-- <span className="text-black-50">Publisher</span> --> */}
                      </span>
                    </small>
                  </a>
                  <a
                    href=""
                    className="dropdown-item d-flex align-items-center"
                  >
                    <div className="avatar avatar-sm mr-8pt">
                      <span className="avatar-title rounded bg-accent">TS</span>
                    </div>

                    <small className="ml-4pt flex">
                      <span className="d-flex flex-column">
                        <strong className="text-black-100">Tech Support</strong>
                        {/* <!-- <span className="text-black-50">Publisher</span> --> */}
                      </span>
                    </small>
                  </a>
                </div>
              </div>

              <form
                action="index.html"
                className="search-form flex-shrink-0 search-form--black sidebar-m-b sidebar-p-l mx-16pt pr-0"
              >
                <input
                  type="text"
                  className="form-control pl-0"
                  placeholder="Search"
                />
                <button className="btn" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </form>

              <div className="sidebar-heading">Overview</div>
              <ul className="sidebar-menu">
                <li className="sidebar-menu-item active">
                  <NavLink className="sidebar-menu-button" to="/admin">
                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">
                      insert_chart_outlined
                    </span>
                    <span className="sidebar-menu-text">Home</span>
                  </NavLink>
                </li>
                <li className="sidebar-menu-item">
                  <NavLink className="sidebar-menu-button" to="/admin/users">
                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">
                      insert_chart_outlined
                    </span>
                    <span className="sidebar-menu-text">Users</span>
                  </NavLink>
                </li>
                {/* <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button"
                                   href="index.html">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">insert_chart_outlined</span>
                                    <span className="sidebar-menu-text">Teams</span>
                                </a>
                            </li> */}
              </ul>

              <div className="sidebar-heading">Applications</div>
              <ul className="sidebar-menu">
                <li className="sidebar-menu-item">
                  <NavLink
                    className="sidebar-menu-button"
                    to="/admin/qflo/clients"
                  >
                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">
                      insert_chart_outlined
                    </span>
                    <span className="sidebar-menu-text">QFlo</span>
                  </NavLink>
                </li>
                {/* <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button"
                                   href="/admin/qflo/clients">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">insert_chart_outlined</span>
                                    <span className="sidebar-menu-text">QAppoint</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button"
                                   href="/admin/qforms/clients">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">insert_chart_outlined</span>
                                    <span className="sidebar-menu-text">QForms</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button"
                                   href="/admin/analytics">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">insert_chart_outlined</span>
                                    <span className="sidebar-menu-text">Analytics</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button"
                                   href="/admin/supportcenter">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">insert_chart_outlined</span>
                                    <span className="sidebar-menu-text">Support center</span>
                                </a>
                            </li> */}
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- // END drawer --> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    me: () => dispatch(getCurrentUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminWrapper);
