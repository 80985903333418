import React, { Component } from "react";
import { getOrgDepartments } from "../api/QfloAPI/departmentAPIService";

class QfloServiceInfoCard extends Component {
  state = {
    isUpdating: false,
    service: { locations: [] },
    departments: [],
  };

  updateInfo = () => {};

  saveInfo = () => {};

  componentDidMount() {
    this.setState({ service: this.props.service });
  }

  render() {
    return (
      <>
        <div className="page-section">
          <div className="page-separator">
            <div className="page-separator__text">Service</div>
          </div>
          <div className="list-group list-group-form">
            <div className="list-group-item">
              <div
                role="group"
                aria-labelledby="label-question"
                className="m-0 form-group"
              >
                <div className="form-row">
                  <label
                    id="label-question"
                    for="question"
                    className="col-md-3 col-form-label form-label"
                  >
                    Name
                  </label>
                  <div className="col-md-9">
                    <input
                      id="sgroup_name"
                      type="text"
                      placeholder="name"
                      value={this.state.service.serviceName}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div
                role="group"
                aria-labelledby="label-question"
                className="m-0 form-group"
              >
                <div className="form-row">
                  <label
                    id="label-question"
                    for="question"
                    className="col-md-3 col-form-label form-label"
                  >
                    Prefix
                  </label>
                  <div className="col-md-9">
                    <input
                      id="sgroup_prefix"
                      type="text"
                      placeholder="prefix"
                      value={this.state.service.prefix}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div
                role="group"
                aria-labelledby="label-question"
                className="m-0 form-group"
              >
                <div className="form-row">
                  <label
                    id="label-question"
                    for="question"
                    className="col-md-3 col-form-label form-label"
                  >
                    Daily Service Quota
                  </label>
                  <div className="col-md-9">
                    <input
                      id="sgroup_prefix"
                      type="text"
                      placeholder="Daily Service Quota"
                      value={this.state.service.serviceCap}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div
                role="group"
                aria-labelledby="label-question"
                className="m-0 form-group"
              >
                <div className="form-row">
                  <label
                    id="label-question"
                    for="question"
                    className="col-md-3 col-form-label form-label"
                  >
                    Is Remote
                  </label>
                  <div className="col-md-9">
                    <select
                      id="service_locations"
                      data-toggle="select"
                      className="form-control"
                    >
                      {this.state.service.isRemote ? (
                        <option selected>YES</option>
                      ) : (
                        <option selected>NO</option>
                      )}
                      <option>NO</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-separator">
            <div className="page-separator__text">Locations</div>
          </div>
          <div className="card mb-0">
            <div
              className="table-responsive"
              data-toggle="lists"
              data-lists-sort-by="js-lists-values-employee-name"
              data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'
            >
              <div className="card-header">
                <form className="form-inline">
                  <label
                    className="mr-sm-2 form-label"
                    for="inlineFormFilterBy"
                  >
                    Filter by:
                  </label>
                  <input
                    type="text"
                    className="form-control search mb-2 mr-sm-2 mb-sm-0"
                    id="inlineFormFilterBy"
                    placeholder="Search ..."
                  />

                  <label className="sr-only" for="inlineFormRole">
                    branch
                  </label>
                  <select
                    id="inlineFormRole"
                    className="custom-select mb-2 mr-sm-2 mb-sm-0"
                  >
                    <option value="All Roles">Branch 1</option>
                  </select>

                  <div className="ml-auto mb-2 mb-sm-0 custom-control-inline mr-0">
                    <label className="form-label mb-0" for="active">
                      Active
                    </label>
                    <div className="custom-control custom-checkbox-toggle ml-8pt">
                      <input
                        checked=""
                        type="checkbox"
                        id="active"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" for="active">
                        Active
                      </label>
                    </div>
                  </div>

                  {/* <!-- <div className="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
        <input type="checkbox" className="custom-control-input" id="inlineFormPurchase">
        <label className="custom-control-label" for="inlineFormPurchase">Made a Purchase?</label>
        </div> --> */}
                </form>
              </div>

              <table className="table mb-0 thead-border-top-0 table-nowrap">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="sort"
                        data-sort="js-lists-values-employee-name"
                      >
                        Name
                      </span>
                    </th>

                    <th className="text-center" style={{ width: "48px" }}>
                      <span
                        className="sort"
                        data-sort="js-lists-values-projects"
                      >
                        isActive
                      </span>
                    </th>

                    <th style={{ width: "37px" }}>Serve Time</th>

                    <th style={{ width: "120px" }}>
                      <span
                        className="sort"
                        data-sort="js-lists-values-activity"
                      >
                        Wait Time
                      </span>
                    </th>
                    <th style={{ width: "24px" }} className="pl-0"></th>
                  </tr>
                </thead>
                <tbody className="list" id="staff">
                  {this.state.service?.locations.map((loc) => (
                    <tr className="selected">
                      <td>
                        <div
                          className="media flex-nowrap align-items-center"
                          style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        >
                          <div className="media-body">
                            <div className="d-flex flex-column">
                              <p className="mb-0">
                                <strong className="js-lists-values-employee-name">
                                  {loc.location.branchName}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className="chip chip-outline-secondary">
                          {loc.isActive ? "ACTIVE" : "DISABLED"}
                        </span>
                      </td>

                      <td className="text-50 js-lists-values-activity small">
                        {loc.serveTime} sec
                      </td>
                      <td className="text-50 js-lists-values-activity small">
                        {loc.waitTime} sec
                      </td>
                      <td className="text-right pl-0">
                        <span className="text-50">
                          <i className="material-icons">more_vert</i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="card-footer p-8pt">
              <ul className="pagination justify-content-start pagination-xsm m-0">
                <li className="page-item disabled">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true" className="material-icons">
                      chevron_left
                    </span>
                    <span>Prev</span>
                  </a>
                </li>
                <li className="page-item dropdown">
                  <a
                    className="page-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    aria-label="Page"
                  >
                    <span>1</span>
                  </a>
                  <div className="dropdown-menu">
                    <a href="" className="dropdown-item active">
                      1
                    </a>
                    <a href="" className="dropdown-item">
                      2
                    </a>
                    <a href="" className="dropdown-item">
                      3
                    </a>
                    <a href="" className="dropdown-item">
                      4
                    </a>
                    <a href="" className="dropdown-item">
                      5
                    </a>
                  </div>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span>Next</span>
                    <span aria-hidden="true" className="material-icons">
                      chevron_right
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            {/* <!-- <div className="card-body bordet-top text-right">
        15 <span className="text-50">of 1,430</span> <a href="#" className="text-50"><i className="material-icons ml-1">arrow_forward</i></a>
        </div> --> */}
          </div>
        </div>
      </>
    );
  }
}

export default QfloServiceInfoCard;
