const initState = {
  client: {},
  clients: [],
  contact: {},
};

const clientsReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_CLIENTS":
      return {
        ...state,
        clients: action.data,
      };
    case "GET_CLIENT":
      return {
        ...state,
        client: action.data,
      };
    case "GET_CONTACT_PERSON":
      return {
        ...state,
        contact: action.data,
      };
    default:
      break;
  }
  return state;
};

export default clientsReducer;
