import React, { Component } from "react";

class QfloCounterInfoCard extends Component {

    state = {
        isUpdating:false
    }
  
  
    updateInfo = () => {

    }

    saveInfo = () =>{

    }

    render() {
  
      return (
        
        <>
            <div className="page-section">
                <div className="page-separator">
                    <div className="page-separator__text">Counter</div>
                </div>
                <div className="list-group list-group-form">
                    
                    <div className="list-group-item">
                        <div className="form-group row align-items-center mb-0">
                            <label className="col-form-label form-label col-sm-3">Name</label>
                            <div className="col-sm-9">
                                <input type="text"
                                        className="form-control"
                                        value="Huma.com/alexander"
                                        placeholder="Your profile name ..."/>
                                
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="form-group row align-items-center mb-0">
                            <label className="col-form-label form-label col-sm-3">Branch</label>
                            <div className="col-sm-9">
                                <select id="counter_branch"
                                                    data-toggle="select"
                                                    className="form-control">
                                                <option selected="">My first option</option>
                                                <option>Another option</option>
                                                <option>Third option is here</option>
                                            </select>
                                
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="form-group row align-items-center mb-0">
                            <label className="col-form-label form-label col-sm-3">Department</label>
                            <div className="col-sm-9">
                                <select id="counter_department"
                                                    data-toggle="select"
                                                    className="form-control">
                                                <option selected="">My first option</option>
                                                <option>Another option</option>
                                                <option>Third option is here</option>
                                            </select>
                                
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="form-group row align-items-center mb-0">
                            <label className="col-form-label form-label col-sm-3">Services</label>
                            <div className="col-sm-9">
                                <select id="kiosk_services"
                                        data-toggle="select"
                                        multiple
                                        className="form-control">
                                    <option selected="">CSS</option>
                                    <option selected="">HTML</option>
                                    <option>JavaScript</option>
                                    <option>Bootstrap</option>
                                </select>
                                
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="form-group row align-items-center mb-0">
                            <label className="col-form-label form-label col-sm-3">Call Duration</label>
                            <div className="col-sm-9">
                                <input type="text"
                                        className="form-control"
                                        value="Huma.com/alexander"
                                        placeholder="Your profile name ..."/>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
      );
  
    }
  
}

export default QfloCounterInfoCard