import { createBrowserHistory as history } from "history";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminWrapper from "./containers/AdminWrapper";
import Login from "./containers/Login";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div
      className="mdk-drawer-layout js-mdk-drawer-layout"
      data-push
      data-responsive-width="992px"
    >
      <BrowserRouter history={history}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin/*" element={<AdminWrapper />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
