import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import QfloAccountAdminInfoOnboardingCard from "../components/QfloAccountAdminInfoOnboardingCard";
import QfloBranchInfoOnboardingCard from "../components/QfloBranchInfoOnboardingCard";
import QfloContactPersonInfoOnboardingCard from "../components/QfloContactPersonInfoOnboardingCard";
import QfloCounterInfoOnboardingCard from "../components/QfloCounterInfoOnboardingCard";
import QfloKioskInfoOnboardingCard from "../components/QfloKioskInfoOnboardingCard";
import QfloOrgInfoOnboardingCard from "../components/QfloOrgInfoOnboardingCard";
import QfloServiceGroupInfoOnboardingCard from "../components/QfloServiceGroupInfoOnboardingCard";
import QfloServiceInfoOnboardingCard from "../components/QfloServiceInfoOnboardingCard";

class ClientOnboarding extends Component {
  state = {};

  render() {
    return (
      <>
        <div className="border-bottom-2 py-32pt position-relative z-1">
          <div className="container-fluid page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
            <div className="flex d-flex flex-column flex-sm-row align-items-center mb-24pt mb-md-0">
              <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                <h2 className="mb-0">OnBoarding</h2>

                <ol className="breadcrumb p-0 m-0">
                  <li className="breadcrumb-item">
                    <NavLink to="/admin">Home</NavLink>
                  </li>

                  <li className="breadcrumb-item">
                    <NavLink to="/admin/qflo/clients">QFlo</NavLink>
                  </li>

                  <li className="breadcrumb-item active">Onboarding</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid page__container"
          style={{ minHeight: "60vh", paddingTop: "2em", paddingBottom: "2em" }}
        >
          <div action="discussions.html">
            <div className="row">
              <div className="col-lg-9">
                <QfloOrgInfoOnboardingCard />

                <QfloContactPersonInfoOnboardingCard />

                <QfloAccountAdminInfoOnboardingCard />

                <QfloBranchInfoOnboardingCard />

                {/* <QfloServiceInfoOnboardingCard/>

                                <QfloServiceGroupInfoOnboardingCard/>

                                <QfloKioskInfoOnboardingCard/>
                                
                                <QfloCounterInfoOnboardingCard/> */}
              </div>
              <div className="col-lg-3 page-nav">
                <div
                  data-perfect-scrollbar
                  data-perfect-scrollbar-wheel-propagation="true"
                >
                  <div className="page-section pt-lg-112pt">
                    <div className="nav page-nav__menu">
                      <a className="nav-link active">Onboarding Flow</a>
                    </div>
                    <div className="page-nav__content">
                      <ul>
                        <li className="text-70">Org Information.</li>
                        <li className="text-70">Contact Person</li>
                        <li className="text-70">Account Admin</li>
                        <li className="text-70">Branch</li>
                        <li className="text-70">Services</li>
                        <li className="text-70">Service groups</li>
                        <li className="text-70">kiosk</li>
                        <li className="text-70">Counter</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientOnboarding;
