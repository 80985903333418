import { genesisClient } from "../client";

export async function login(body) {
  let response = await genesisClient.post(`/login`, body);
  sessionStorage.setItem("genesis.qflo.app", response.data.access_token);
}

export function getUser() {
  return genesisClient.get(`/me`);
}
