import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import QfloBillingInfoCard from "../components/QfloBillingInfoCard";
import QfloBranchListInfoCard from "../components/QfloBranchListInfoCard";
import QfloContactPersonInfoCard from "../components/QfloContactPersonInfoCard";
import QfloCounterListInfoCard from "../components/QfloCounterListInfoCard";
import QfloDepartmentListInfoCard from "../components/QfloDepartmentListInfoCard";
import QfloEmployeesInfoCard from "../components/QfloEmployeesInfoCard";
import QfloKioskListInfoCard from "../components/QfloKioskListInfoCard";
import QfloOrgInfoCard from "../components/QfloOrgInfoCard";
import QfloServiceGroupListInfoCard from "../components/QfloServiceGroupListInfoCard";
import QfloServicesListInfoCard from "../components/QfloServicesListInfoCard";
import { getOrganisation } from "../store/actions/clientActions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

class Client extends Component {
  state = {
    focusArea: 1,
  };

  componentDidMount() {
    //TODO
    //Get client info

    let splitPath = window.location.pathname.split("/");

    const exttoast = toast.loading("Getting client !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });

    this.props
      .getClient(splitPath[4])
      .then((res) => {
        // console.log(res);

        toast.update(exttoast, {
          render: "Complete",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.update(exttoast, {
          render: "Failed to get client!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
  }

  render() {
    return (
      <>
        <div className="border-bottom-2 py-32pt position-relative z-1">
          <div className="container-fluid page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
            <div className="flex d-flex flex-column flex-sm-row align-items-center">
              <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                <h2 className="mb-0">{this.props.client.organisationName}</h2>

                <ol className="breadcrumb p-0 m-0">
                  <li className="breadcrumb-item">
                    <NavLink to="/admin">Home</NavLink>
                  </li>

                  <li className="breadcrumb-item">
                    <NavLink to="/admin/qflo/clients">QFlo</NavLink>
                  </li>

                  <li className="breadcrumb-item active">
                    {this.props.client.organisationName}
                  </li>
                </ol>
              </div>
            </div>
            <div className="row" role="tablist">
              <div className="col-auto">
                <h6 className="m-0">Start Date</h6>
                <p className="text-50 mb-0 d-flex align-items-center">
                  {moment(this.props.client.createdAt).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="col-auto border-left">
                <NavLink
                  to="#"
                  className="d-flex align-items-center"
                  data-caret="false"
                >
                  <img
                    width="32"
                    height="32"
                    className="rounded-circle mr-8pt"
                    src="/assets/images/people/50/guy-3.jpg"
                    alt="account"
                  />
                  <span className="flex d-flex flex-column mr-8pt">
                    <span className="text-black-100">Laza Bogdan</span>
                    <small className="text-black-50">Account manager</small>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid page__container"
          style={{ minHeight: "60vh", paddingTop: "2em", paddingBottom: "2em" }}
        >
          <div className="row">
            <div className="col-lg-9 pr-lg-0">
              {this.state.focusArea == 1 ? (
                <QfloOrgInfoCard client={this.props.client} />
              ) : this.state.focusArea == 2 ? (
                <QfloContactPersonInfoCard client={this.props.client} />
              ) : this.state.focusArea == 3 ? (
                <QfloBranchListInfoCard client={this.props.client} />
              ) : this.state.focusArea == 4 ? (
                <QfloEmployeesInfoCard client={this.props.client} />
              ) : this.state.focusArea == 5 ? (
                <QfloDepartmentListInfoCard client={this.props.client} />
              ) : this.state.focusArea == 6 ? (
                <QfloServicesListInfoCard client={this.props.client} />
              ) : this.state.focusArea == 7 ? (
                <QfloServiceGroupListInfoCard client={this.props.client} />
              ) : this.state.focusArea == 8 ? (
                <QfloCounterListInfoCard client={this.props.client} />
              ) : this.state.focusArea == 9 ? (
                <QfloKioskListInfoCard client={this.props.client} />
              ) : this.state.focusArea == 10 ? (
                <QfloBillingInfoCard client={this.props.client} />
              ) : (
                <QfloOrgInfoCard client={this.props.client} />
              )}
            </div>
            <div className="col-lg-3 page-nav">
              <div className="page-section pt-lg-112pt">
                <nav className="nav page-nav__menu">
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 1 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Organisation Information
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 2 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Contact person
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 3 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Branches
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 4 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Employees
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 5 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Departments
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 6 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Services
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 7 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Service groups
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 8 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Counters
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 9 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Kiosks
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 10 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Billing Information
                  </a>
                  <a
                    onClick={() => {
                      this.setState({ focusArea: 11 });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Settings
                  </a>
                </nav>
                <div className="page-nav__content">
                  <button type="submit" className="btn btn-accent">
                    Edit Info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.clientData.client,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClient: (id) => dispatch(getOrganisation(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Client);
