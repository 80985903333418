import React, { Component } from "react";

class QfloBillingInfoCard extends Component {

    state = {
        isUpdating:false
    }
  
  
    updateInfo = () => {

    }

    saveInfo = () =>{

    }

    render() {
  
      return (
        
        <>
            <div className="page-section">
            <div className="page-separator">
                <div className="page-separator__text">Billing</div>
            </div>
                <div className="list-group list-group-form">
                    <div className="list-group-item">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-3">Subscription tier</label>
                            <div className="col-sm-9 d-flex align-items-center">
                                <div className="flex">Basic, &dollar;9 per month</div>
                                <a href="billing-upgrade.html"
                                    className="text-secondary">Change plan</a>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-3">Billing cycle</label>
                            <div className="col-sm-9">
                                <p>You will be charged $9 on Aug 20, 2018</p>
                                {/* <!-- <div className="custom-control custom-checkbox">
                                    <input type="checkbox"
                                            className="custom-control-input"
                                            checked
                                            id="customCheck1">
                                    <label className="custom-control-label"
                                            for="customCheck1">Enable automatic renewal</label>
                                </div> --> */}
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-3">Payment information</label>
                            <div className="col-sm-9 d-flex align-items-center">
                                <img src="/assets/images/visa.svg"
                                        alt="visa"
                                        width="38"
                                        className="mr-16pt"/>
                                <div className="flex">Visa ending with 2819</div>
                                <a href="billing-payment.html"
                                    className="text-secondary">Change method</a>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-3">Cancel</label>
                            <div className="col-sm-9">
                                <a href=""
                                    className="btn btn-outline-secondary">Cancel subscription</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-section">
                <h4>Payments</h4>

                <div className="alert alert-soft-warning mb-lg-32pt">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-8pt">
                            <i className="material-icons">access_time</i>
                        </div>
                        <div className="flex"
                                style={{minWidth: "180px"}}>
                            <small className="text-100">
                                Please pay your amount due of
                                <strong>&dollar;9.00</strong> for invoice <a href="billing-invoice.html"
                                    className="text-underline">10002331</a>
                            </small>
                        </div>
                        <a href="billing-payment.html"
                            className="btn btn-sm btn-link">Pay Now</a>
                    </div>
                </div>

                <div className="page-separator">
                    <div className="page-separator__text">Payment History</div>
                </div>

                <div className="card table-responsive">
                    <table className="table table-flush table-nowrap">
                        <thead>
                            <tr>
                                <th>Invoice no.</th>
                                <th>Date</th>
                                <th className="text-center">Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td><a href="billing-invoice.html"
                                        className="text-underline">10002331</a></td>
                                <td>26 Sep 2018</td>
                                <td className="text-center">&dollar;9</td>
                                <td className="text-right">
                                    <div className="d-inline-flex align-items-center">
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary mr-16pt">View invoice <i className="icon--right material-icons">keyboard_arrow_right</i></a>
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary">Download <i className="icon--right material-icons">file_download</i></a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td><a href="billing-invoice.html"
                                        className="text-underline">10003815</a></td>
                                <td>29 Apr 2018</td>
                                <td className="text-center">&dollar;9</td>
                                <td className="text-right">
                                    <div className="d-inline-flex align-items-center">
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary mr-16pt">View invoice <i className="icon--right material-icons">keyboard_arrow_right</i></a>
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary">Download <i className="icon--right material-icons">file_download</i></a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td><a href="billing-invoice.html"
                                        className="text-underline">10007382</a></td>
                                <td>31 Mar 2018</td>
                                <td className="text-center">&dollar;9</td>
                                <td className="text-right">
                                    <div className="d-inline-flex align-items-center">
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary mr-16pt">View invoice <i className="icon--right material-icons">keyboard_arrow_right</i></a>
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary">Download <i className="icon--right material-icons">file_download</i></a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td><a href="billing-invoice.html"
                                        className="text-underline">10004876</a></td>
                                <td>30 May 2018</td>
                                <td className="text-center">&dollar;9</td>
                                <td className="text-right">
                                    <div className="d-inline-flex align-items-center">
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary mr-16pt">View invoice <i className="icon--right material-icons">keyboard_arrow_right</i></a>
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary">Download <i className="icon--right material-icons">file_download</i></a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td><a href="billing-invoice.html"
                                        className="text-underline">10009392</a></td>
                                <td>30 Apr 2018</td>
                                <td className="text-center">&dollar;9</td>
                                <td className="text-right">
                                    <div className="d-inline-flex align-items-center">
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary mr-16pt">View invoice <i className="icon--right material-icons">keyboard_arrow_right</i></a>
                                        <a href="billing-invoice.html"
                                            className="btn btn-sm btn-outline-secondary">Download <i className="icon--right material-icons">file_download</i></a>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
      );
  
    }
  
}

export default QfloBillingInfoCard