import React, { Component } from "react";

class QfloContactPersonInfoCard extends Component {
  state = {
    isUpdating: false,
    contactPerson: {},
  };

  updateInfo = () => {};

  saveInfo = () => {};

  componentDidMount() {
    // get contact person
  }

  render() {
    return this.state.isUpdating ? (
      <div className="page-section">
        {/* <div className="page-separator">
                    <div className="page-separator__text">Contact Person</div>
                </div> */}
        <form className="pb-32pt pb-lg-64pt">
          <div className="o-hidden mb-0">
            <div className="list-group list-group-form">
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <div className="col-sm-12 media align-items-center">
                    <h5 className="text-uppercase mb-0">
                      Contact Person information
                    </h5>
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Profile photo
                  </label>
                  <div className="col-sm-9 media align-items-center">
                    <a href="" className="media-left mr-16pt">
                      <img
                        src="/assets/images/people/110/guy-3.jpg"
                        alt="people"
                        width="56"
                        className="rounded-circle"
                      />
                    </a>
                    <div className="media-body">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="inputGroupFile01"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value="Huma.com/alexander"
                      placeholder="Your profile name ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div
                  role="group"
                  aria-labelledby="label-question"
                  className="m-0 form-group"
                >
                  <div className="form-row">
                    <label
                      id="label-question"
                      htmlFor="question"
                      className="col-md-3 col-form-label form-label"
                    >
                      Gender
                    </label>
                    <div className="col-md-9">
                      <select
                        id="select03"
                        data-toggle="select"
                        className="form-control"
                      >
                        <option selected="">Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Work Email
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value="Huma.com/alexander"
                      placeholder="Your profile name ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Work Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value="Huma.com/alexander"
                      placeholder="Your profile name ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div
                  className="form-group m-0"
                  role="group"
                  aria-labelledby="label-title"
                >
                  <div className="form-row">
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-accent"
                        style={{ float: "right" }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <div className="page-section">
        {/* <div className="page-separator">
                <div className="page-separator__text">Contact Person</div>
            </div> */}

        <div className="list-group list-group-form">
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Profile photo
              </label>
              <div className="col-sm-9 media align-items-center">
                <a href="" className="media-left mr-16pt">
                  <img
                    src="/assets/images/people/110/guy-3.jpg"
                    alt="people"
                    width="56"
                    className="rounded-circle"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">Name</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={this.state.contactPerson.name}
                  placeholder="Name"
                />
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Work Email
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.contactPerson.email}
                  disabled
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Work Number
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={this.state.contactPerson.number}
                  placeholder="Work number"
                />
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group row align-items-center mb-0">
              <label className="col-form-label form-label col-sm-3">
                Gender
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={this.state.contactPerson.gender}
                  placeholder="Gender"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QfloContactPersonInfoCard;
