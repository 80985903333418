import { qfloClient } from "../client";

export async function getClients(body) {
  return qfloClient.get(`/clients`, body);
}

export function getClient(client) {
  return qfloClient.get(`/client?id=${client}`);
}

export function setupClient(client) {
  return qfloClient.post(`/client`, client);
}

export function setupContactPerson(body) {
  return qfloClient.post(`/client/contactperson`, body);
}
