const initState = {
  clientUser: {},
};

const clientUserReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_CLIENT_USER":
      return {
        ...state,
        clientUser: action.data,
      };

    default:
      break;
  }
  return state;
};

export default clientUserReducer;
