import React, { Component } from "react";
import GoogleMap from "google-maps-react-markers";
import QFloMapMarker from "./QFloMapMarker";
class QfloBranchInfoCard extends Component {
  state = {
    isUpdating: false,
  };

  updateInfo = () => {};

  saveInfo = () => {};

  render() {
    return (
      <>
        <div className="page-section">
          <div className="page-separator">
            <div className="page-separator__text">Branch</div>
          </div>
          <div className="list-group list-group-form">
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Name
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value={this.props.branch.branchName}
                    placeholder="Branch name ..."
                  />
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Location
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value={this.props.branch.country}
                    placeholder="Branch location"
                  />
                </div>
              </div>
            </div>
            {/* <div className="list-group-item">
                        <div className="form-group row align-items-center mb-0">
                            <label className="col-form-label form-label col-sm-3">Services</label>
                            <div className="col-sm-9">
                                <select id="kiosk_services"
                                        data-toggle="select"
                                        multiple
                                        className="form-control">
                                    <option selected="">CSS</option>
                                    <option selected="">HTML</option>
                                    <option>JavaScript</option>
                                    <option>Bootstrap</option>
                                </select>
                                
                            </div>
                        </div>
                    </div> */}
          </div>
        </div>
        <div className="page-section" style={{ height: "40vh", width: "100%" }}>
          <GoogleMap
            apiKey="AIzaSyAjwovKZ11EaLszLXhYUTHDC89Rs2QEfC4"
            defaultCenter={{
              lat: this.props.branch.location.lat,
              lng: this.props.branch.location.long,
            }}
            defaultZoom={10}
            // options={mapOptions}
            mapMinHeight="100%"
            // onGoogleApiLoaded={onGoogleApiLoaded}
            // onChange={(map) => console.log("Map moved", map)}
          >
            <QFloMapMarker
              key={this.props.branch._id}
              lat={this.props.branch.location.lat}
              lng={this.props.branch.location.long}
              markerId={this.props.branch._id}
              // onClick={onMarkerClick} // you need to manage this prop on your Marker component!
              draggable={false}
              // onDragStart={(e, { latLng }) => {}}
              // onDrag={(e, { latLng }) => {}}
              // onDragEnd={(e, { latLng }) => {
              //   this.markerMoved(latLng);
              // }}
            />
          </GoogleMap>
        </div>
      </>
    );
  }
}

export default QfloBranchInfoCard;
