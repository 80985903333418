import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { registerClientUser } from "../store/actions/clientUserActions";

class QfloAccountAdminInfoOnboardingCard extends Component {
  state = {
    isNew: true,
    firstname: null,
    lastname: null,
    email: null,
    userType: "account_admin",
    password: null,
    serviceModule: "qflo",
    gender: "male",
    number: null,
  };

  updateInfo = () => {};

  saveInfo = (e) => {
    e.preventDefault();

    let body = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      userType: this.state.userType,
      password: this.state.password,
      serviceModule: this.state.serviceModule,
      gender: this.state.gender,
      number: this.state.number,
      organisationId: this.props.client._id,
    };

    const exttoast = toast.loading("Setting up Accoount Admin !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });

    this.props
      .setupClientAdmin(body)
      .then((res) => {
        // console.log(re);
        toast.update(exttoast, {
          render: "Complete",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.update(exttoast, {
          render: "Failed to setup Account Admin !",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
  };

  render() {
    return this.state.isNew ? (
      <div className="page-section">
        <div className="page-separator">
          <div className="page-separator__text">Account Admin</div>
        </div>
        <form
          className="card--connect pb-32pt pb-lg-64pt"
          onSubmit={(e) => this.saveInfo(e)}
        >
          <div className="card o-hidden mb-0">
            <div className="card-header bg-transparent">
              <h5 className="text-uppercase mb-0">Account Admin Information</h5>
            </div>
            <div className="list-group list-group-form">
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    First Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.firstname}
                      onChange={(e) =>
                        this.setState({ firstname: e.target.value })
                      }
                      placeholder="First name ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Last Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.lastname}
                      onChange={(e) =>
                        this.setState({ lastname: e.target.value })
                      }
                      placeholder="Last name ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div
                  role="group"
                  aria-labelledby="label-question"
                  className="m-0 form-group"
                >
                  <div className="form-row">
                    <label
                      id="label-question"
                      for="question"
                      className="col-md-3 col-form-label form-label"
                    >
                      Gender
                    </label>
                    <div className="col-md-9">
                      <select
                        id="select03"
                        data-toggle="select"
                        className="form-control"
                        value={this.state.gender}
                        onChange={(e) =>
                          this.setState({ gender: e.target.value })
                        }
                      >
                        <option selected value="male">
                          Male
                        </option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Work Email
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder="email ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Work Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.number}
                      onChange={(e) =>
                        this.setState({ number: e.target.value })
                      }
                      placeholder="Number ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="form-group row align-items-center mb-0">
                  <label className="col-form-label form-label col-sm-3">
                    Password
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      placeholder="Password ..."
                    />
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div
                  className="form-group m-0"
                  role="group"
                  aria-labelledby="label-title"
                >
                  <div className="form-row">
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-accent"
                        style={{ float: "right" }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <div className="page-section">
        <div className="page-separator">
          <div className="page-separator__text">Contact Person</div>
        </div>
        <div className="card">
          <div className="list-group list-group-form">
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Profile photo
                </label>
                <div className="col-sm-9 media align-items-center">
                  <a href="" className="media-left mr-16pt">
                    <img
                      src="/assets/images/people/110/guy-3.jpg"
                      alt="people"
                      width="56"
                      className="rounded-circle"
                    />
                  </a>
                  <div className="media-body">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFile01"
                      />
                      <label
                        className="custom-file-label"
                        for="inputGroupFile01"
                      >
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Name
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value="Huma.com/alexander"
                    placeholder="Your profile name ..."
                  />
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Work Email
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value="Huma.com/alexander"
                    placeholder="Your profile name ..."
                  />
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Work Number
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value="Huma.com/alexander"
                    placeholder="Your profile name ..."
                  />
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Gender
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value="Huma.com/alexander"
                    placeholder="Gender"
                  />
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="form-group row align-items-center mb-0">
                <label className="col-form-label form-label col-sm-3">
                  Password
                </label>
                <div className="col-sm-9">
                  <input
                    type="password"
                    className="form-control"
                    value="Huma.com/alexander"
                    placeholder="Your profile name ..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.clientData.client,
    user: state.userData.user,
    clientUser: state.clientUserData.clientUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setupClientAdmin: (data) => dispatch(registerClientUser(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QfloAccountAdminInfoOnboardingCard);
