import React, { Component } from "react";
import QfloKioskInfoCard from "./QfloKioskInfoCard";

class QfloKioskListInfoCard extends Component {

    state = {
        isUpdating:false,
        selectedKiosk:{},
        focusArea:"kioskList"
    }
  
  
    updateInfo = () => {

    }

    saveInfo = () =>{

    }

    selectKiosk = (kiosk) =>{
        this.setState({selectedKiosk:kiosk, focusArea:"kiosk"})
    }

    render() {
  
      return (
        this.state.focusArea == "kioskList"? 
        
            <div className="page-section">
                <div className="page-separator">
                    <div className="page-separator__text">Kiosks</div>
                </div>

                <div className="card mb-0">

                <div className="table-responsive"
                    data-toggle="lists"
                    data-lists-sort-by="js-lists-values-employee-name"
                    data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'>

                        <div className="card-header">
                            <form className="form-inline">
                                <label className="mr-sm-2 form-label"
                                        for="inlineFormFilterBy">Filter by:</label>
                                <input type="text"
                                        className="form-control search mb-2 mr-sm-2 mb-sm-0"
                                        id="inlineFormFilterBy"
                                        placeholder="Search ..."/>

                                <label className="sr-only"
                                        for="inlineFormRole">branch</label>
                                <select id="inlineFormRole"
                                        className="custom-select mb-2 mr-sm-2 mb-sm-0">
                                    <option value="All Roles">Branch 1</option>
                                </select>

                                <div className="ml-auto mb-2 mb-sm-0 custom-control-inline mr-0">
                                    <label className="form-label mb-0"
                                            for="active">Active</label>
                                    <div className="custom-control custom-checkbox-toggle ml-8pt">
                                        <input checked=""
                                                type="checkbox"
                                                id="active"
                                                className="custom-control-input"/>
                                        <label className="custom-control-label"
                                                for="active">Active</label>
                                    </div>
                                </div>

                                {/* <!-- <div className="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
        <input type="checkbox" className="custom-control-input" id="inlineFormPurchase">
        <label className="custom-control-label" for="inlineFormPurchase">Made a Purchase?</label>
        </div> --> */}
                            </form>
                        </div>

                        <table className="table mb-0 thead-border-top-0 table-nowrap">
                            <thead>
                                <tr>

                                    <th style={{width: "18px"}}
                                        className="pr-0">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                    className="custom-control-input js-toggle-check-all"
                                                    data-target="#staff"
                                                    id="customCheckAllstaff"/>
                                            <label className="custom-control-label"
                                                    for="customCheckAllstaff"><span className="text-hide">Toggle all</span></label>
                                        </div>
                                    </th>

                                    <th>
                                        <span
                                            className="sort"
                                            data-sort="js-lists-values-employee-name">Name</span>
                                    </th>

                                    

                                    <th className="text-center"
                                        style={{width: "48px"}}>
                                        <span
                                            className="sort"
                                            data-sort="js-lists-values-projects">Branch</span>
                                    </th>

                                    <th style={{width: "37px"}}>Status</th>

                                    <th style={{width: "120px"}}>
                                        <span
                                            className="sort"
                                            data-sort="js-lists-values-activity">Services</span>
                                    </th>
                                    
                                    <th style={{width: "24px"}}
                                        className="pl-0"></th>
                                </tr>
                            </thead>
                            <tbody className="list"
                                    id="staff">

                                <tr className="selected">

                                    <td className="pr-0">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                    className="custom-control-input js-check-selected-row"
                                                    checked=""
                                                    id="customCheck1_1"/>
                                            <label className="custom-control-label"
                                                    for="customCheck1_1"><span className="text-hide">Check</span></label>
                                        </div>
                                    </td>

                                    <td onClick={() => this.selectKiosk({})}>

                                        <div className="media flex-nowrap align-items-center"
                                                style={{whiteSpace: "nowrap", cursor:"pointer"}}>
                                            
                                            <div className="media-body">

                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><strong className="js-lists-values-employee-name">Name</strong></p>
                                                    <small className="js-lists-values-employee-email text-50">KioskId</small>
                                                </div>

                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span
                                                className="text-70"><span className="js-lists-values-employer-name">Black Ops</span></span>
                                        </div>
                                    </td>
                                    <td>

                                        <span
                                            className="chip chip-outline-secondary">Admin</span>

                                    </td>

                                    <td className="text-50 js-lists-values-activity small">3 days ago</td>
                                    <td className="text-right pl-0">
                                        <span
                                            className="text-50"><i className="material-icons">more_vert</i></span>
                                    </td>
                                </tr>

                                <tr>

                                    <td className="pr-0">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                    className="custom-control-input js-check-selected-row"
                                                    id="customCheck1_2"/>
                                            <label className="custom-control-label"
                                                    for="customCheck1_2"><span className="text-hide">Check</span></label>
                                        </div>
                                    </td>

                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                                style={{whiteSpace: "nowrap"}}>
                                            
                                            <div className="media-body">

                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><strong className="js-lists-values-employee-name">Name</strong></p>
                                                    <small className="js-lists-values-employee-email text-50">KioskId</small>
                                                </div>

                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex align-items-center">
                                            <a href=""
                                                className="text-70"><span className="js-lists-values-employer-name">Backend Ltd</span></a>
                                        </div>
                                    </td>

                                    <td>

                                        <a href=""
                                            className="chip chip-outline-secondary">User</a>

                                    </td>

                                    <td className="text-50 js-lists-values-activity small">1 week ago</td>
                                    <td className="text-right pl-0">
                                        <a href=""
                                            className="text-50"><i className="material-icons">more_vert</i></a>
                                    </td>
                                </tr>

                                <tr>

                                    <td className="pr-0">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                    className="custom-control-input js-check-selected-row"
                                                    id="customCheck1_3"/>
                                            <label className="custom-control-label"
                                                    for="customCheck1_3"><span className="text-hide">Check</span></label>
                                        </div>
                                    </td>

                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                                style={{whiteSpace: "nowrap"}}>
                                            
                                            <div className="media-body">

                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><strong className="js-lists-values-employee-name">Name</strong></p>
                                                    <small className="js-lists-values-employee-email text-50">KioskId</small>
                                                </div>

                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex align-items-center">
                                            <a href=""
                                                className="text-70"><span className="js-lists-values-employer-name">Frontted</span></a>
                                        </div>
                                    </td>
                                    <td>

                                        <a href=""
                                            className="chip chip-outline-secondary">Manager</a>

                                    </td>

                                    <td className="text-50 js-lists-values-activity small">2 weeks ago</td>
                                    <td className="text-right pl-0">
                                        <a href=""
                                            className="text-50"><i className="material-icons">more_vert</i></a>
                                    </td>
                                </tr>

                                <tr className="selected">

                                    <td className="pr-0">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                    className="custom-control-input js-check-selected-row"
                                                    checked=""
                                                    id="customCheck1_4"/>
                                            <label className="custom-control-label"
                                                    for="customCheck1_4"><span className="text-hide">Check</span></label>
                                        </div>
                                    </td>

                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                                style={{whiteSpace: "nowrap"}}>
                                            
                                            <div className="media-body">

                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><strong className="js-lists-values-employee-name">Name</strong></p>
                                                    <small className="js-lists-values-employee-email text-50">KioskId</small>
                                                </div>

                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex align-items-center">
                                            <a href=""
                                                className="text-70"><span className="js-lists-values-employer-name">Frontted</span></a>
                                        </div>
                                    </td>

                                    <td>

                                        <a href=""
                                            className="chip chip-outline-secondary">Admin</a>

                                    </td>

                                    <td className="text-50 js-lists-values-activity small">3 weeks ago</td>
                                    <td className="text-right pl-0">
                                        <a href=""
                                            className="text-50"><i className="material-icons">more_vert</i></a>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div className="card-footer p-8pt">

                        <ul className="pagination justify-content-start pagination-xsm m-0">
                            <li className="page-item disabled">
                                <a className="page-link"
                                    href="#"
                                    aria-label="Previous">
                                    <span aria-hidden="true"
                                            className="material-icons">chevron_left</span>
                                    <span>Prev</span>
                                </a>
                            </li>
                            <li className="page-item dropdown">
                                <a className="page-link dropdown-toggle"
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-label="Page">
                                    <span>1</span>
                                </a>
                                <div className="dropdown-menu">
                                    <a href=""
                                        className="dropdown-item active">1</a>
                                    <a href=""
                                        className="dropdown-item">2</a>
                                    <a href=""
                                        className="dropdown-item">3</a>
                                    <a href=""
                                        className="dropdown-item">4</a>
                                    <a href=""
                                        className="dropdown-item">5</a>
                                </div>
                            </li>
                            <li className="page-item">
                                <a className="page-link"
                                    href="#"
                                    aria-label="Next">
                                    <span>Next</span>
                                    <span aria-hidden="true"
                                            className="material-icons">chevron_right</span>
                                </a>
                            </li>
                        </ul>

                    </div>
                    {/* <!-- <div className="card-body bordet-top text-right">
    15 <span className="text-50">of 1,430</span> <a href="#" className="text-50"><i className="material-icons ml-1">arrow_forward</i></a>
    </div> --> */}

                </div>
            </div>
        :

        this.state.focusArea == "kiosk" ?
            <QfloKioskInfoCard kiosk={this.state.selectedKiosk}/>
        :
        <>
        </>

      );
  
    }
  
}

export default QfloKioskListInfoCard