import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import StaffNewInfoCard2 from "../components/StaffNewInfoCard2";
import WithRouter from "../components/WithRouter";

class Staff extends Component {

  constructor(props) {
    super(props);
  }
    state = {
      focusArea:"list"
    }
  
    selectUser = (userId) => {
        this.props.router.navigate(`/admin/users/${userId}`)
    }
  
    render() {
  
      return (
        <>
          <div className="border-bottom-2 py-32pt position-relative z-1">
            <div className="container-fluid page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                <div className="flex d-flex flex-column flex-sm-row align-items-center">

                    <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                        <h2 className="mb-0">Staff</h2>

                        <ol className="breadcrumb p-0 m-0">
                            <li className="breadcrumb-item"><NavLink to="/admin">Home</NavLink></li>

                            <li className="breadcrumb-item active">

                                <NavLink to="/admin/users">Staff</NavLink>

                            </li>

                            

                        </ol>

                    </div>

                    

                </div>
                <div className="row"
                    role="tablist">
                    
                      {
                      this.state.focusArea == "newMember" ? 
                      <>
                        <div className="col-auto">
                          <a onClick={() => this.setState({focusArea:"list"})} className="btn btn-accent">Back</a>
                        </div>
                        <div className="col-auto border-left">
                          <a className="btn btn-accent">Save Info</a>
                        </div>
                      </>
                      :
                      <>
                        <div className="col-auto border-left">
                          <a onClick={() => this.setState({focusArea:"newMember"})} className="btn btn-accent">Add  Staff</a>
                        </div>
                      </>
                      }
                        
                    
                </div>

            </div>
        </div>
        <div className="container-fluid page__container" style={{minHeight: "60vh", paddingTop:"2em",  paddingBottom:"2em"}}>
            <div className="row">
                <div className="col-lg-12 pr-lg-0">

                  {
                  this.state.focusArea == "newMember" ?
                  <StaffNewInfoCard2/>
                  :
                  <div className="page-section">
                    <div className="page-separator">
                        <div className="page-separator__text">Staff</div>
                    </div>

                    <div className="card mb-0">

                        <div className="table-responsive"
                              data-toggle="lists"
                              data-lists-sort-by="js-lists-values-employee-name"
                              data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'>

                            <div className="card-header">
                                <form className="form-inline">
                                    <label className="mr-sm-2 form-label"
                                            htmlFor="inlineFormFilterBy">Filter by:</label>
                                    <input type="text"
                                            className="form-control search mb-2 mr-sm-2 mb-sm-0"
                                            id="inlineFormFilterBy"
                                            placeholder="Search ..."/>

                                    <label className="sr-only"
                                            htmlFor="inlineFormRole">Roll</label>
                                    <select id="inlineFormRole"
                                            className="custom-select mb-2 mr-sm-2 mb-sm-0">
                                        <option value="All Roles">All Roll</option>
                                    </select>

                                    <div className="ml-auto mb-2 mb-sm-0 custom-control-inline mr-0">
                                        <label className="form-label mb-0"
                                                htmlFor="active">Active</label>
                                        <div className="custom-control custom-checkbox-toggle ml-8pt">
                                            <input checked
                                                    onChange={() => {}}
                                                    type="checkbox"
                                                    id="active"
                                                    className="custom-control-input"/>
                                            <label className="custom-control-label"
                                                    htmlFor="active">Active</label>
                                        </div>
                                    </div>

                                    {/* <!-- <div className="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
        <input type="checkbox" className="custom-control-input" id="inlineFormPurchase">
        <label className="custom-control-label" htmlFor="inlineFormPurchase">Made a Purchase?</label>
      </div> --> */}
                                </form>
                            </div>

                            <table className="table mb-0 thead-border-top-0 table-nowrap">
                                <thead>
                                    <tr>
                                        <th>
                                            <span
                                                className="sort"
                                                data-sort="js-lists-values-employee-name">Names</span>
                                        </th>

                                        <th className="text-center"
                                            style={{width: "100px"}}>
                                            <span
                                                className="sort"
                                                data-sort="js-lists-values-projects">Role</span>
                                        </th>

                                        <th className="text-center"
                                            style={{width: "48px"}}>
                                            <span
                                                className="sort"
                                                data-sort="js-lists-values-projects">Country</span>
                                        </th>

                                        <th style={{width: "37px"}}>Status</th>

                                        <th style={{width: "120px"}}>
                                            <span
                                                className="sort"
                                                data-sort="js-lists-values-activity">Onboarding Date</span>
                                        </th>
                                        
                                        <th style={{width: "24px"}}
                                            className="pl-0"></th>
                                    </tr>
                                </thead>
                                <tbody className="list"
                                        id="staff">

                                    <tr className="selected">

                                        <td onClick={() => this.selectUser(1)}  style={{cursor:"pointer"}}>

                                            <div className="media flex-nowrap align-items-center"
                                                  style={{whiteSpace: "nowrap"}}>
                                                <div className="avatar avatar-32pt mr-8pt">

                                                    <img src="/assets/images/people/110/guy-1.jpg"
                                                          alt="Avatar"
                                                          className="avatar-img rounded-circle"/>

                                                </div>
                                                <div className="media-body">

                                                    <div  className="d-flex flex-column">
                                                        <p className="mb-0"><strong className="js-lists-values-employee-name">Michael Smith</strong></p>
                                                        <small className="js-lists-values-employee-email text-50">Some email address</small>
                                                    </div>

                                                </div>
                                            </div>

                                        </td>

                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span
                                                    className="text-70"><span className="js-lists-values-employer-name">Administrator</span></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span
                                                    className="text-70"><span className="js-lists-values-employer-name">Botswana</span></span>
                                            </div>
                                        </td>
                                        <td>

                                            <span
                                                className="chip chip-outline-secondary">Active</span>

                                        </td>

                                        <td className="text-50 js-lists-values-activity small">3 days ago</td>
                                        <td className="text-right pl-0">
                                            <span
                                                className="text-50"><i className="material-icons">more_vert</i></span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>

                                            <div className="media flex-nowrap align-items-center"
                                                  style={{whiteSpace: "nowrap"}}>
                                                <div className="avatar avatar-32pt mr-8pt">

                                                    <span className="avatar-title rounded-circle">CS</span>

                                                </div>
                                                <div className="media-body">

                                                    <a href="GenStaffAccount.html" className="d-flex flex-column">
                                                        <p className="mb-0"><strong className="js-lists-values-employee-name">Connie Smith</strong></p>
                                                        <small className="js-lists-values-employee-email text-50">Some email address</small>
                                                    </a>

                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <a href=""
                                                    className="text-70"><span className="js-lists-values-employer-name">Administrator</span></a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <a href=""
                                                    className="text-70"><span className="js-lists-values-employer-name">Bostwana</span></a>
                                            </div>
                                        </td>
                                        
                                        <td>

                                            <a href=""
                                                className="chip chip-outline-secondary">Active</a>

                                        </td>

                                        <td className="text-50 js-lists-values-activity small">1 week ago</td>
                                        <td className="text-right pl-0">
                                            <a href=""
                                                className="text-50"><i className="material-icons">more_vert</i></a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>

                                            <div className="media flex-nowrap align-items-center"
                                                  style={{whiteSpace: "nowrap"}}>
                                                <div className="avatar avatar-32pt mr-8pt">

                                                    <img src="/assets/images/people/110/guy-2.jpg"
                                                          alt="Avatar"
                                                          className="avatar-img rounded-circle"/>

                                                </div>
                                                <div className="media-body">

                                                    <div href="GenStaffAccount.html" className="d-flex flex-column">
                                                        <p className="mb-0"><strong className="js-lists-values-employee-name">John Connor</strong></p>
                                                        <small className="js-lists-values-employee-email text-50">Some email address</small>
                                                    </div>

                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <a href=""
                                                    className="text-70"><span className="js-lists-values-employer-name">Administrator</span></a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <a href=""
                                                    className="text-70"><span className="js-lists-values-employer-name">Bostwana</span></a>
                                            </div>
                                        </td>
                                        <td>

                                            <a href=""
                                                className="chip chip-outline-secondary">Active</a>

                                        </td>

                                        <td className="text-50 js-lists-values-activity small">2 weeks ago</td>
                                        <td className="text-right pl-0">
                                            <a href=""
                                                className="text-50"><i className="material-icons">more_vert</i></a>
                                        </td>
                                    </tr>

                                    <tr className="selected">
                                        <td>

                                            <div className="media flex-nowrap align-items-center"
                                                  style={{whiteSpace: "nowrap"}}>
                                                <div className="avatar avatar-32pt mr-8pt">

                                                    <span className="avatar-title rounded-circle">LB</span>

                                                </div>
                                                <div className="media-body">

                                                    <a href="GenStaffAccount.html" className="d-flex flex-column">
                                                        <p className="mb-0"><strong className="js-lists-values-employee-name">Laza Bogdan</strong></p>
                                                        <small className="js-lists-values-employee-email text-50">Some email address</small>
                                                    </a>

                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <a href=""
                                                    className="text-70"><span className="js-lists-values-employer-name">Administrator</span></a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <a href=""
                                                    className="text-70"><span className="js-lists-values-employer-name">Zambia</span></a>
                                            </div>
                                        </td>

                                        <td>

                                            <a href=""
                                                className="chip chip-outline-secondary">Active</a>

                                        </td>

                                        <td className="text-50 js-lists-values-activity small">3 weeks ago</td>
                                        <td className="text-right pl-0">
                                            <a href=""
                                                className="text-50"><i className="material-icons">more_vert</i></a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <div className="card-footer p-8pt">

                            <ul className="pagination justify-content-start pagination-xsm m-0">
                                <li className="page-item disabled">
                                    <a className="page-link"
                                        href="#"
                                        aria-label="Previous">
                                        <span aria-hidden="true"
                                              className="material-icons">chevron_left</span>
                                        <span>Prev</span>
                                    </a>
                                </li>
                                <li className="page-item dropdown">
                                    <a className="page-link dropdown-toggle"
                                        data-toggle="dropdown"
                                        href="#"
                                        aria-label="Page">
                                        <span>1</span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <a href=""
                                            className="dropdown-item active">1</a>
                                        <a href=""
                                            className="dropdown-item">2</a>
                                        <a href=""
                                            className="dropdown-item">3</a>
                                        <a href=""
                                            className="dropdown-item">4</a>
                                        <a href=""
                                            className="dropdown-item">5</a>
                                    </div>
                                </li>
                                <li className="page-item">
                                    <a className="page-link"
                                        href="#"
                                        aria-label="Next">
                                        <span>Next</span>
                                        <span aria-hidden="true"
                                              className="material-icons">chevron_right</span>
                                    </a>
                                </li>
                            </ul>

                        </div>
                        {/* <!-- <div className="card-body bordet-top text-right">
  15 <span className="text-50">of 1,430</span> <a href="#" className="text-50"><i className="material-icons ml-1">arrow_forward</i></a>
  </div> --> */}

                    </div>
                </div>
                  }
                    
                    
                    
                </div>
                
            </div>
        </div>
        </>
      );
  
    }
  
}

export default WithRouter(Staff)