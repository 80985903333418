import { createOrgUser } from "../../api/QfloAPI/userAPIService";

export function registerClientUser(body) {
  return (dispatch) => {
    return createOrgUser(body)
      .then((response) => {
        let user;
        try {
          user =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
          return user;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_CLIENT_USER",
            data: user,
          });
          return Promise.resolve(user);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}
